import React from 'react';
import { Button, IconButton, Menu, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import randomColor from 'randomcolor';
import axios from '../../utils/AxiosWrapper';
import { HorizontalBar } from 'react-chartjs-2';
import DashboardFilters from './DashboardFilters';
import Chart from './Dashboards/Chart';

const styles = theme => ({ card: { minWidth: 275, padding: '10px', width: '95%', }, });

export default class Analytics extends React.Component {
    constructor(props) {
        super();
        this.state = {
            season: [], sector: [], crop: [], field: [], plan: [], operation: [],
            revenueTotal: null, materialTotal: null, workerTotal: null,

            materialLabels: [], materialValues: [],
            materialGroupLabels: [], materialGroupValues: [],
            materialSubgroupLabels: [], materialSubgroupValues: [],
            colorsM: [], colorsMG: [], colorsMSG: [],

            materialUsedLabels: [], materialUsedValues: [],
            materialUsedGroupLabels: [], materialUsedGroupValues: [],
            materialUsedSubgroupLabels: [], materialUsedSubgroupValues: [],
            colorsMU: [], colorsMUG: [], colorsMUSG: [],

            revenueLabels: [], revenueValues: [],
            revenueSubgroupLabels: [], revenueSubgroupValues: [],
            colorsR: [], colorsRSG: [],

            revenueUsedLabels: [], revenueUsedValues: [],
            revenueUsedGroupLabels: [], revenueUsedGroupValues: [],
            revenueUsedSubgroupLabels: [], revenueUsedSubgroupValues: [],
            colorsRU: [], colorsRUG: [], colorsRUSG: [],

            // workerLabels: [], workerValues: [],
            // operationLabels: [], operationValues: [],
            // workerOperationLabels: [], workerOperationValues: [],
            // colorsW: [], colorsO: [], colorsWO: [],

            workerLabels: [], workerValues: [], colorsW: [],
            workPlaceLabels: [], workPlaceValues: [], colorsWP: [],

            materialVisible: false, revenueVisible: false, workerVisible: false,
            valuta: ''
        }
    }

    componentDidMount() {
        this.getValuta()
        this.getData([], [], [], [], [], [], 'IV_materijal_enterprise_reports');
        this.getData([], [], [], [], [], [], 'IV_prinos_enterprise_reports');
        this.getData([], [], [], [], [], [], 'api/report/read_workers_report');
    }

    getValuta() {
        axios.get(`api/valuta/valuta`)
            .then(res => {
                let regionId = res.data.data[0].id

                if (regionId === 2) this.setState({ valuta: ' RSD' });
                else if (regionId === 20) this.setState({ valuta: ' BAM' });
                else if (regionId === 21) this.setState({ valuta: ' BAM' });
                else if (regionId === 22) this.setState({ valuta: ' EUR' });
                else if (regionId === 23) this.setState({ valuta: ' EUR' });
                else if (regionId === 13) this.setState({ valuta: ' USD' });
                else if (regionId === 25) this.setState({ valuta: ' ILS' });
                else if (regionId === 26) this.setState({ valuta: ' CAD' })
                else if (regionId === 27) this.setState({ valuta: ' ILS' })
                else if (regionId === 29) this.setState({ valuta: ' EUR' })
                else if (regionId === 30) this.setState({ valuta: ' EUR' })
                else if (regionId === 31) this.setState({ valuta: ' RSD' })
            })
            .catch(error => { console.log('error', error); })
    }

    getData(season, sector, crop, field, plan, operation, tabela, total, property) {

        if (tabela === 'api/report/read_workers_report') {
            axios.get(tabela, { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, operation: operation, tabela: tabela, } })
                .then(response => {

                    if (plan.length === 0) {
                        if (response.data.data.length === 0) this.setState({
                            workerLabels: [], workerValues: [], colorsW: [],
                            workPlaceLabels: [], workPlaceValues: [], colorsWP: [],
                        });

                        else {
                            let objW = formatWorkerData(response.data.data, 'radnik_naziv', 'cena');
                            let objWP = formatWorkerData(response.data.data, 'radno_mesto_naziv', 'cena');

                            this.setState({
                                workerLabels: objW.labels, workerValues: objW.values, colorsW: objW.colors,
                                workPlaceLabels: objWP.labels, workPlaceValues: objWP.values, colorsWP: objWP.colors,
                            });
                        }
                    }
                    else {
                        this.setState({
                            workerLabels: [], workerValues: [], colorsW: [],
                            workPlaceLabels: [], workPlaceValues: [], colorsWP: [],
                        });
                    }

                })
                .catch(error => console.log('Error: ', error));
        }

        else {
            axios.get('api/report/read_dashboard', { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, operation: [], tabela: tabela } })
                .then(response => {
                    let sum = 0;
                    for (let i = 0; i < response.data.total; i++) sum += Number(response.data.data[i][property]);
                    this.setState({ [total]: sum });

                    // function formatData(data, name1, sep1, name2, sep2, name3, cena, currency) {
                    // item[name1] + sep1 + item[name2] + sep2 + item[name3]

                    if (tabela === 'IV_materijal_enterprise_reports') {
                        if (response.data.data.length === 0) {
                            this.setState({
                                materialLabels: [], materialValues: [],
                                materialGroupLabels: [], materialGroupValues: [],
                                materialSubgroupLabels: [], materialSubgroupValues: [],
                                colorsM: [], colorsMG: [], colorsMSG: [],

                                materialUsedLabels: [], materialUsedValues: [],
                                materialUsedGroupLabels: [], materialUsedGroupValues: [],
                                materialUsedSubgroupLabels: [], materialUsedSubgroupValues: [],
                                colorsMU: [], colorsMUG: [], colorsMUSG: [],
                            })
                        }
                        else {
                            let objM = formatData(response.data.data, '', '', '', '', 'materijal_naziv', 'cena_utroseno', true);
                            let objMG = formatData(response.data.data, '', '', '', '', 'materijal_grupa_naziv', 'cena_utroseno', true);
                            let objMSG = formatData(response.data.data, '', '', '', '', 'materijal_podgrupa_naziv', 'cena_utroseno', true);

                            let objMU = formatData(response.data.data, 'materijal_naziv', ' / ', 'jedinica_mere', '', '', 'utroseno', false);
                            let objMUG = formatData(response.data.data, 'materijal_grupa_naziv', ' / ', 'jedinica_mere', '', '', 'utroseno', false);
                            let objMUSG = formatData(response.data.data, 'materijal_podgrupa_naziv', ' / ', 'jedinica_mere', '', '', 'utroseno', false);

                            this.setState({
                                materialLabels: objM.labels, materialValues: objM.values, colorsM: objM.colors,
                                materialGroupLabels: objMG.labels, materialGroupValues: objMG.values, colorsMG: objMG.colors,
                                materialSubgroupLabels: objMSG.labels, materialSubgroupValues: objMSG.values, colorsMSG: objMSG.colors,

                                materialUsedLabels: objMU.labels, materialUsedValues: objMU.values, colorsMU: objMU.colors,
                                materialUsedGroupLabels: objMUG.labels, materialUsedGroupValues: objMUG.values, colorsMUG: objMUG.colors,
                                materialUsedSubgroupLabels: objMUSG.labels, materialUsedSubgroupValues: objMUSG.values, colorsMUSG: objMUSG.colors,
                            });
                        }
                    }

                    else if (tabela === 'IV_prinos_enterprise_reports') {
                        if (response.data.data.length === 0) {
                            this.setState({
                                revenueLabels: [], revenueValues: [],
                                revenueSubgroupLabels: [], revenueSubgroupValues: [],
                                colorsR: [], colorsRSG: [],

                                revenueUsedLabels: [], revenueUsedValues: [],
                                revenueUsedGroupLabels: [], revenueUsedGroupValues: [],
                                revenueUsedSubgroupLabels: [], revenueUsedSubgroupValues: [],
                                colorsRU: [], colorsRUG: [], colorsRUSG: [],
                            })
                        }
                        else {
                            let objR = formatData(response.data.data, '', '', '', '', 'materijal_naziv', 'cena_prinos', true);
                            let objRSG = formatData(response.data.data, '', '', '', '', 'materijal_podgrupa_naziv', 'cena_prinos', true);

                            let objRU = formatData(response.data.data, 'materijal_naziv', ' / ', 'jedinica_mere', '', '', 'prinos', false);
                            let objRUG = formatData(response.data.data, 'materijal_grupa_naziv', ' / ', 'jedinica_mere', '', '', 'prinos', false);
                            let objRUSG = formatData(response.data.data, 'materijal_podgrupa_naziv', ' / ', 'jedinica_mere', '', '', 'prinos', false);

                            this.setState({
                                revenueLabels: objR.labels, revenueValues: objR.values, colorsR: objR.colors,
                                revenueSubgroupLabels: objRSG.labels, revenueSubgroupValues: objRSG.values, colorsRSG: objRSG.colors,

                                revenueUsedLabels: objRU.labels, revenueUsedValues: objRU.values, colorsRU: objRU.colors,
                                revenueUsedGroupLabels: objRUG.labels, revenueUsedGroupValues: objRUG.values, colorsRUG: objRUG.colors,
                                revenueUsedSubgroupLabels: objRUSG.labels, revenueUsedSubgroupValues: objRUSG.values, colorsRUSG: objRUSG.colors,
                            });
                        }
                    }

                    // else if (tabela === 'IV_radnik_enterprise') {
                    //     let objO = formatData(response.data.data, '', '', '', '', 'naziv_radna_operacija', 'iznos', true);
                    //     let objW = formatData(response.data.data, 'ime_radnika', ' ', 'prezime_radnika', '', '', 'iznos', true);
                    //     let objWO = formatData(response.data.data, 'ime_radnika', ' ', 'prezime_radnika', ' / ', 'naziv_radna_operacija', 'iznos', true);

                    //     this.setState({
                    //         workerLabels: objW.labels, workerValues: objW.values, colorsW: objW.colors,
                    //         operationLabels: objO.labels, operationValues: objO.values, colorsO: objO.colors,
                    //         workerOperationLabels: objWO.labels, workerOperationValues: objWO.values, colorsWO: objWO.colors,
                    //     });
                    // }
                })
                .catch(error => { console.log('Error: ', error); });
        }
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    render() {
        let valutaRegion;
        if (this.state.valuta !== '') valutaRegion = this.state.valuta
        else valutaRegion = ' '

        let materialTotalCost = null;
        materialTotalCost = this.state.materialValues.reduce((a, b) => a + b, 0);
        materialTotalCost = Math.round(materialTotalCost * 100) / 100;

        let revenueTotalCost = null;
        revenueTotalCost = this.state.revenueValues.reduce((a, b) => a + b, 0);
        revenueTotalCost = Math.round(revenueTotalCost * 100) / 100;

        let workerTotalCost = null;
        workerTotalCost = this.state.workerValues.reduce((a, b) => a + b, 0);
        workerTotalCost = Math.round(workerTotalCost * 100) / 100;

        let materialTotal = 0, revenueTotal = 0, costTotal = 0, workerTotal = 0;
        if (this.state.materialValues !== null && this.state.workerValues !== null) costTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(materialTotalCost + workerTotalCost));
        // if (this.state.materialValues !== null) costTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(materialTotalCost));
        if (this.state.materialValues !== null) materialTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(materialTotalCost));
        if (this.state.revenueValues !== null) revenueTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(revenueTotalCost));
        if (this.state.workerValues !== null) workerTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(workerTotalCost));

        let revenue_string = i18n.t('Revenue');
        let material_string = i18n.t('Material Cost');
        let worker_string = i18n.t('Worker Cost');

        let profitDoughnut = {
            datasets: [{
                data: [
                    revenueTotalCost,
                    materialTotalCost,
                    workerTotalCost
                ],
                backgroundColor: [
                    '#86c74a',
                    '#f06654',
                    '#ebaf26'
                ],
                label: '',
            }],
            labels: [
                `${revenue_string}: ${revenueTotal} ${valutaRegion}`,
                `${material_string}: ${materialTotal} ${valutaRegion}`,
                `${worker_string}: ${workerTotal}` + this.state.valuta,
            ],
        };

        const options = {
            animation: { duration: 0 },
            hover: { animationDuration: 0 },
            responsiveAnimationDuration: 0,
            legend: { display: false },
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0
                    }
                }]
            }
        };

        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {materialTotal &&
                                workerTotal &&
                                revenueTotal &&
                                <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Revenue')}: {revenueTotal} {valutaRegion} / {i18n.t('Cost')}: {costTotal} {valutaRegion}</h1>
                            }
                        </div>
                    </Card>
                </div>
                <br></br>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <DashboardFilters
                        handleChange={this.handleChange}
                        season={this.state.season} sector={this.state.sector} crop={this.state.crop} field={this.state.field} plan={this.state.plan} operation={this.state.operation} operation_visible={this.state.workerVisible}
                    />
                    <Button
                        onClick={(e) => {
                            let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [], operationValues = [];

                            seasonValues = this.getValues(seasonValues, this.state.season);
                            sectorValues = this.getValues(sectorValues, this.state.sector);
                            cropValues = this.getValues(cropValues, this.state.crop);
                            fieldValues = this.getValues(fieldValues, this.state.field);
                            planValues = this.getValues(planValues, this.state.plan);
                            operationValues = this.getValues(operationValues, this.state.operation);

                            this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'IV_materijal_enterprise_reports');
                            this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'IV_prinos_enterprise_reports');
                            this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'api/report/read_workers_report');
                        }}
                        style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '10%', marginTop: '32px', marginLeft: '10px' }}>
                        {i18n.t('Submit')}
                    </Button>
                    <Button
                        onClick={(e) => {
                            this.setState({ season: [], sector: [], crop: [], field: [], plan: [], operation: [] }, () => {
                                let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [], operationValues = [];

                                seasonValues = this.getValues(seasonValues, this.state.season);
                                sectorValues = this.getValues(sectorValues, this.state.sector);
                                cropValues = this.getValues(cropValues, this.state.crop);
                                fieldValues = this.getValues(fieldValues, this.state.field);
                                planValues = this.getValues(planValues, this.state.plan);
                                operationValues = this.getValues(operationValues, this.state.operation);

                                this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'IV_materijal_enterprise_reports');
                                this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'IV_prinos_enterprise_reports');
                                this.getData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'api/report/read_workers_report');
                            });
                        }}
                        style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '10%', marginTop: '32px', marginLeft: '20px' }}
                    >
                        {i18n.t('Reset')}
                    </Button>
                </div>
                <br></br>
                <div style={{ width: '100%' }}>
                    <Card style={styles.card}>
                        <h1 style={{ marginLeft: '10px', marginBottom: '-10px' }}>{i18n.t('Revenue / Cost')}:</h1>
                        <HorizontalBar data={profitDoughnut} redraw options={options} height={15} width={100} onElementsClick={elems => {
                            if (elems[0] !== undefined) {
                                if (elems[0]._model.label.includes(revenue_string) === true) this.setState({
                                    revenueVisible: !this.state.revenueVisible,
                                    materialVisible: false,
                                    workerVisible: false
                                });
                                if (elems[0]._model.label.includes(material_string) === true) this.setState({
                                    materialVisible: !this.state.materialVisible,
                                    revenueVisible: false,
                                    workerVisible: false
                                });
                                if (elems[0]._model.label.includes(worker_string) === true) this.setState({
                                    workerVisible: !this.state.workerVisible,
                                    materialVisible: false,
                                    revenueVisible: false
                                });
                            }
                        }} />
                        <br></br>
                    </Card>
                </div>
                <div>
                    {this.state.materialVisible &&
                        <div>
                            <br></br>
                            <div >
                                <Chart width={100} title={i18n.t('Material Cost by Group')} color={this.state.colorsMG} labels={this.state.materialGroupLabels} values={this.state.materialGroupValues} currency={true} />
                            </div> <br></br>
                            <div >
                                <Chart width={100} title={i18n.t('Material Cost by Subgroup')} color={this.state.colorsMSG} labels={this.state.materialSubgroupLabels} values={this.state.materialSubgroupValues} currency={true} />
                            </div> <br></br>
                            <div >
                                <Chart width={100} title={i18n.t('Material Cost')} color={this.state.colorsM} labels={this.state.materialLabels} values={this.state.materialValues} currency={true} />
                            </div>
                        </div>
                    }
                    {this.state.revenueVisible &&
                        <div>
                            <br></br>
                            <div >
                                <Chart width={100} title={i18n.t('Revenue by Subgroup')} color={this.state.colorsRSG} labels={this.state.revenueSubgroupLabels} values={this.state.revenueSubgroupValues} currency={true} />
                            </div> <br></br>
                            <div >
                                <Chart width={100} title={i18n.t('Revenue')} color={this.state.colorsR} labels={this.state.revenueLabels} values={this.state.revenueValues} currency={true} />
                            </div>
                        </div>
                    }
                    {this.state.workerVisible &&
                        <div>
                            <br></br>
                            <div style={{ width: '94%', marginLeft: '10px' }}>
                                <Chart width={100} title={i18n.t('Cost by Workplace')} color={this.state.colorsWP} labels={this.state.workPlaceLabels} values={this.state.workPlaceValues} currency={true} />
                            </div> <br></br>
                            <div style={{ width: '94%', marginLeft: '10px' }}>
                                <Chart width={100} title={i18n.t('Cost by Worker')} color={this.state.colorsW} labels={this.state.workerLabels} values={this.state.workerValues} currency={true} />
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

function getLabels(item, labels) {
    labels.push(item);
    let unique = new Set(labels);
    labels = [...unique];
    return labels;
};

function getData(values, filter, cena) {
    let quant = 0;
    filter.map((mat) => {
        if (mat[cena] !== undefined && mat[cena] > 0) {
            quant += parseFloat(mat[cena]);
        }
    });
    values.push(quant.toFixed(2));
    return values;
};

function formatData(data, name1, sep1, name2, sep2, name3, cena, currency) {
    let temp_labels = [], labels = [], values = [];

    if (name1 !== '' && name2 !== '' && name3 !== '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name1] + sep1 + item[name2] + sep2 + item[name3], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name1] + sep1 + i[name2] + sep2 + i[name3] === item); values = getData(values, filter, cena) });
    }
    else if (name1 === '' && name2 === '' && name3 !== '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name3], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name3] === item); values = getData(values, filter, cena) });
    }
    else if (name1 !== '' && name2 !== '' && name3 === '') {
        data.forEach((item, i) => temp_labels = getLabels(item[name1] + sep1 + item[name2], temp_labels).sort());
        temp_labels.forEach(item => { let filter = data.filter(i => i[name1] + sep1 + i[name2] === item); values = getData(values, filter, cena) });
    }
    values = values.map(item => Number(item));

    let deleted = values.reduce(function (acc, curr, index) {
        if (curr === 0) acc.push(index);
        return acc;
    }, []);

    let val = [], lab = [];

    for (let i = 0; i < values.length; i++) {
        if (!deleted.includes(i)) {
            val.push(values[i]);
            lab.push(temp_labels[i]);
        }
    }

    temp_labels = lab;
    values = val;

    if (data.length > 0) {
        if (data[0].id_region === 20) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
        else if (data[0].id_region === 13) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' NGN');
        else if (data[0].id_region === 21) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
        else if (data[0].id_region === 2) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' RSD');
        else if (data[0].id_region === 22) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
        else if (data[0].id_region === 23) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
        else if (data[0].id_region === 25) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ILS');
        else if (data[0].id_region === 26) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' CAD');
        else if (data[0].id_region === 27) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ISL');
        else if (data[0].id_region === 29) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
        else if (data[0].id_region === 31) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' RSD');
}

    else labels = temp_labels.map((item, i) => item + ': ' + (values[i].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")));

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}

function formatWorkerData(data, name, cena) {
    let temp_labels = [], labels = [], values = [];

    data.forEach((item, i) => temp_labels = getLabels(item[name], temp_labels).sort());
    temp_labels.forEach(item => { let filter = data.filter(i => i[name] === item); values = getData(values, filter, cena) });
    values = values.map(item => Number(item));
    if (data[0].id_region === 20) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 13) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' NGN');
    else if (data[0].id_region === 21) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 2) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' RSD');
    else if (data[0].id_region === 22) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 23) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 25) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ILS');
    else if (data[0].id_region === 26) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' CAD');
    else if (data[0].id_region === 27) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ISL');
    else if (data[0].id_region === 29) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}