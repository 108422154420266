import React from 'react';
import { connect } from 'react-redux';
import { MoonLoader } from 'react-spinners';
import i18n from '../../i18n/i18n';
import { createNewPassword, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Button, TextField, } from '@material-ui/core';
import validator from 'validator';

export class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password1: '',
            password2: ''
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.createpassword === true) {
            toast.success(i18n.t('You have changed your password!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('createpassword');
            this.props.history.replace('/');

        }
    }

    createNewPassword = () => {
        if (this.state.password1 && this.state.password2) {
            if (this.state.password1 !== this.state.password2) {
                toast.error(i18n.t('Passwords do not match!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else if (!validator.isLength(this.state.password1 && this.state.password2, { min: 6, max: 10 })) {
                toast.error(i18n.t('Password must contain at least 6 letters!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else {
                var params = window.location.search.substring(1);
                var token = params.substring(6);
                this.props.createNewPassword(this.state.password1, token);

            }
        } else {
            toast.error(i18n.t('Please enter all fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });

        }
    }

    render() {
        return (
            <div className="login">
                {this.props.createpasswordFetching ? (
                    <MoonLoader />
                ) : (
                    <div className="loginContainer">
                        <div className="logo">
                            <img src={require('../../assets/images/login_ParkLife.svg')} width="200" height="50" />
                        </div>
                        {this.props.createpassword ? (
                            <>
                                <div>
                                    <p style={{ fontSize: 16, fontWeight: 500 }}>{i18n.t('You have changed your password!')}</p>
                                </div>
                                <div>
                                    <p style={{ width: 250, textAlign: 'center', fontSize: 14, fontWeight: 400, margin: 0 }}>{i18n.t("Now you can login")}</p>
                                </div>
                                <br />
                                <Button
                                    className="loginButton"
                                    variant="contained"
                                    style={{ backgroundColor: '#04764e', color: 'white' }}
                                    onClick={() => this.props.history.push('/')}
                                >
                                    {i18n.t('login')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <div>
                                    <p style={{ fontSize: 16, fontWeight: 500 }}>{i18n.t('New password')}</p>
                                </div>
                                <TextField
                                    required
                                    type='password'
                                    onChange={(event) => this.setState({ password1: event.target.value })}
                                    label={i18n.t('New password')}
                                    style={{ width: '260px' }}
                                />
                                <br />
                                <TextField
                                    type='password'
                                    onChange={(event) => this.setState({ password2: event.target.value })} required
                                    label={i18n.t('Retype new password')}
                                    style={{ width: '260px' }}
                                />
                                <br />
                                <Button
                                    className="loginButton"
                                    variant="contained"
                                    style={{ backgroundColor: '#04764e', color: 'white', textTransform: 'none', marginTop: 10 }}
                                    onClick={() => this.createNewPassword()}
                                >
                                    {i18n.t('Change password')}
                                </Button>
                                <br />
                                <Link
                                    onClick={() => this.props.history.goBack()}
                                    style={{ color: '#000' }}
                                >
                                    {i18n.t('cancel')}
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        createpassword: state.appReducer.createpassword,
        createpasswordFetching: state.appReducer.createpasswordFetching,
        createpasswordFetchingFailed: state.appReducer.createpasswordFetchingFailed,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createNewPassword: (password1, token) => dispatch(createNewPassword(password1, token)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);