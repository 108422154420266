import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Button, Table, Tooltip, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import AddIcon from '@material-ui/icons/Add';
import * as Icons from '@material-ui/icons';
import { toast } from 'react-toastify';
import _ from 'lodash';
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        height: '35px',
        fontWeight: '500',
        fontSize: 16
    },
    body: {
        fontSize: 13,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        paddingRight: 100,
        paddingLeft: 20
    }
}))(TableCell);

const styles = theme => ({
    innerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    card: {
        height: 500,
        width: 600,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column'
    },
    innerContainerTable: {
        width: '100%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    button: {
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        width: '235px'
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    typography: {
        paddingLeft: '20px',
        color: theme.palette.text.secondary,
        fontWeight: '600',
    }
});
export class ActivityField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: [],
            fieldDropDown: [],
            tabla: [],
            add: false
        }
        this.getName = this.getName.bind(this);
    }

    componentDidMount() {
        this.props.getFieldDropDown(this.props.clientProfile.id_default_sezona);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fieldDropDown !== this.props.fieldDropDown) {
            this.setState({
                fieldDropDown: immutable.asMutable(this.props.fieldDropDown.map((item) => { return { label: item.naziv + ' (' + item.povrsina + 'ha' + ') ' + item.kultura_naziv + ', ' + this.getName(item), value: item.id, id_tabla: item.id, naziv: item.naziv, povrsina: item.povrsina, deo_table: item.deo_table } }), { deep: true }),
            })
        }
        if (this.props.table && prevState.table !== this.props.table) {
            this.setState({
                table: this.props.table,
            })
        }
    }
    handleAdd = (tabla) => {
        const { handleAddFields } = this.props;
        const { table } = this.state;
        let diff = _.differenceBy(tabla, table, 'id_tabla');
        handleAddFields('table', diff);
        this.setState({
            tabla: [],
            add: false,
        })
    }
    handleChangeValue = name => selected => {
        this.setState((state) => {
            let tabla = state[name];
            tabla = selected;
            return { ...state, tabla };
        })
    }
    handlePopup = () => {
        this.setState({
            add: !this.state.add
        })
    }
    onDeleteClick = (tabla, index) => {
        const { handleDeleteChild } = this.props;
        const { table } = this.state;
        if (table.length === 1) {
            toast.error(i18n.t('You need to have at least one table on activity!'), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
            });
        } else {
            handleDeleteChild('table', index, tabla);
            this.setState(prevState => {
                let newTables = prevState.table;
                let newTabla = prevState.tabla;
                return { table: newTables.filter((_, i) => i !== index), tabla: newTabla.filter((_, i) => i !== index) };
            })
        }
    }
    getName(tabla) {
        if (tabla.deo_table == true) {
            return i18n.t('Yes');
        } else {
            return i18n.t('No');
        }
    }

    render() {
        const { classes, theme, viewOnly } = this.props;
        const { tabla, table, fieldDropDown } = this.state;
        const tableRender = table && table.map((tabla, i) => {
            if (!tabla) return null;
            return <TableRow key={i}>
                <CustomTableCell>{tabla.naziv && tabla.naziv || tabla.tabla.naziv}</CustomTableCell>
                <CustomTableCell>{tabla.povrsina && tabla.povrsina + ' ha' || tabla.tabla.povrsina + ' ha'}</CustomTableCell>
                <CustomTableCell>{((tabla.tabla && this.getName(tabla.tabla)) || (!tabla.tabla && tabla.deo_table && i18n.t('Yes') || i18n.t('No')))}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            className={classes.cssRoot}
                            disabled={viewOnly}
                            onClick={() => this.onDeleteClick(tabla, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.innerContainer}>
                {this.state.add &&
                    <Dialog
                        open={this.state.add}
                        onClose={this.handlePopup}
                        maxWidth="sm"
                        disableBackdropClick
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle style={{ background: theme.palette.secondary.dark }} disableTypography={true} className="responsive-dialog-title">{i18n.t('Add public green area')}
                            <IconButton onClick={this.handlePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <Card raised className={classes.card}>
                            <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={true}
                                isDisabled={viewOnly}
                                isMulti={true}
                                value={this.state.tabla}
                                label={i18n.t('Public green area')}
                                maxMenuHeight={250}
                                onChange={this.handleChangeValue('tabla')}
                                options={_.differenceBy(fieldDropDown, table, 'id_tabla')}
                                className="multi-select-activity-form"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div className={classes.buttonsRow}>
                                <Button disabled={!tabla} onClick={() => this.handleAdd(tabla)}>{i18n.t('Add')}</Button>
                                <Button onClick={this.handlePopup}>{i18n.t('Close')}</Button>
                            </div>
                        </Card>
                    </Dialog>
                }
                <div className={classes.panel}>
                    <Typography className={classes.typography} variant="subtitle1">{i18n.t('Public green areas') + '*'}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="Add"
                        disabled={viewOnly}
                        className={classes.button}
                        onClick={this.handlePopup}
                    >
                        {'+ ' + i18n.t('Add public green area')}
                    </Button>
                </div>
                <div className={classes.innerContainerTable}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>{i18n.t('Public green area')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Land area')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Part of the field')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Delete')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRender}
                        </TableBody>
                    </Table>

                </div>
            </div >
        )

    }

}


function mapStateToProps(state) {
    return {
        fieldDropDown: state.fieldReducer.fieldDropDown,
        fieldFetchingDropDown: state.fieldReducer.fieldFetchingDropDown,
        fieldFetchingFailedDropDown: state.fieldReducer.fieldFetchingFailedDropDown,
        clientProfile: state.appReducer.clientProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFieldDropDown: (id_default_sezona) => dispatch(getFieldDropDown(id_default_sezona)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityField))