import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';


export default class AlertConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            close: false
        };
    };

    render() {
        return (
            
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.alert}
                    onClose={this.state.close}
                    fullWidth={false}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e', color: "#fff", alignItems: 'center',fontSize: '18px' }} className="responsivedialogtitle">
                        {i18n.t('Public green area is succesfully archived')}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div style={{ padding: '18px 0 0 0', fontSize: '18px', lineHeight: '26px', textAlign: 'justify' }}>
                                {i18n.t('Do you want to add an after-culture to the public green areas?')}
                                <br />
                                <br />
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button variant='contained' color='secondary' onClick={() => {
                                    this.props.addAfterCulture(this.props.data)
                                    this.props.handleCloseAlert();
                                }}>{i18n.t('Yes')}</Button>
                                <Button style={{ marginLeft: '40px' }}variant='contained' color='secondary' onClick={() => {
                                    this.props.handleCloseAlert();
                                    this.props.closeEditDialog();
                                }}>{i18n.t('No')}</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};