import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab, Button } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import moment from 'moment';

const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });
const CheckboxTable = checkboxHOC(ReactTable);;

export default class ArchiveList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0,
            add: false, edit: false, delete: false,
            data: [], alert: false
        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        //this.getByURL('workplace/drop_down', 'workplaceType');
        // this.getByURL('worker/filter_worker_group', 'groupType');
        //this.getByURL('worker/filter_sector', 'sectorType');
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.getData('field/read_parts', this.state);
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    
    // handleArchive = (row) => {
    //     axios.post(`api/field/archive_by_id`, {id: row.id})
    //         .then(res => {
    //             this.getData('field/read_parts', this.state);
    //             this.setState({ delete: false, selection: [] });
    //         })
    //         .catch(err => {
    //             this.setState({ delete: false });
    //             console.log('Error: ', err);
    //         })
    // };

    deleteItem = () => {

        axios.post(`api/field/delete_part`, { selection: this.state.selection })
            .then(res => {
                this.getData('field/read_parts', this.state);
                this.setState({ delete: false, selection: [] });
            })
            .catch(err => {
                this.setState({ delete: false });
                console.log('Error: ', err);
            })
    }

    handleCloseAlert = () => {
        this.setState({ alert: !this.state.alert });
    }

    archivePart = () => {
        console.log('props za arhiviranje')
    }

    handleOpenAlert = (row) => {
        console.log('row' , row)
        this.setState({alert: true, row: row})
    }


    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); }
    onTableClick = () => this.props.history.push('/partsofthefield');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); }

    render() {
        const columns = [
            { Header: i18n.t('Title'), accessor: 'naziv', width: 100 },
            { Header: i18n.t('Land area')+' (ha)', accessor: 'povrsina', width: 100 },
            { Header: i18n.t('Number of seedlings'), accessor: 'broj_cokota',  width: 100},
            { Header: i18n.t('Crop'), accessor: 'kultura_naziv',  width: 100},
            { Header: i18n.t('Sort/Hybrid'), accessor: 'kultura_sorta',  width: 100},
            { Header: i18n.t('Crop subgroup'), accessor: 'kultura_podgrupa',  width: 150},
            { Header: i18n.t('Crop group'), accessor: 'kultura_grupa',  width: 150},
            // { Header: i18n.t('Local goverment'), accessor: 'katastarska_opstina',  width: 100},
            { Header: i18n.t('Type of public green areas'), accessor: 'vrsta_proizvodnje',  width: 150},
            
            // { Header: i18n.t('Name'), accessor: 'naziv' },
            // { Header: i18n.t('Table'), accessor: 'tabla_naziv' },
            // { Header: i18n.t('Crop'), accessor: 'kultura_naziv' },
            // { Header: i18n.t('Sort'), accessor: 'sorta_naziv' },
            // { Header: i18n.t('Surface')+' (ha)', accessor: 'povrsina' },
            // { Header: i18n.t('Vreme kreiranja'), accessor: 'vreme_kreiranja', Cell: ({ value }) => moment(value).format('DD-MM-YYYY, HH mm') },
            { Header: i18n.t('Date created'), accessor: 'vreme_kreiranja', Cell: ({ value }) => moment(value).format('DD.MM.YYYY.'), width: 100 },
            { Header: i18n.t('Date archived'), accessor: 'arhiviranje_datum', Cell: ({ value }) => moment(value).format('DD.MM.YYYY.'), width: 100 },
          
        ];
        const url = 'field/read_archive';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#acdaf1" : "inherit", alignItems: 'flex-end' } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                   
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#58B4E5',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
            </div>
        );
    }
}

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}