import i18n from '../i18n/i18n';

export const getSelectedPage = (url) => {
  switch (url) {
    case '/activities':
      return i18n.t('Activities');
    case '/new_activity':
      return i18n.t('Activity')
    case '/maps':
      return i18n.t('View map');
    case '/parcel':
      return i18n.t('Parcels');
    case '/field':
      return i18n.t('Public green areas');
    case '/partsofthefield':
      return i18n.t('Parts of the public green areas');
    case '/archivelist':
      return i18n.t('Archived public green areas');
    case '/notes':
      return i18n.t('Notes');
    case '/sensors':
      return i18n.t('Sensors');
    case '/material':
      return i18n.t('Materials');
    case '/crop':
      return i18n.t('Crops');
    case '/varieties':
      return i18n.t('Crop varieties');
    case '/machines':
      return i18n.t('Machines');
    case '/implement':
      return i18n.t('Implements');
    case '/working_operation':
      return i18n.t('Working operations');
    case '/work_activities':
      return i18n.t('Worker activities');
    case '/workers':
      return i18n.t('Workers');
    case '/workplaces':
      return i18n.t('Workplaces');
    case '/work_units':
      return i18n.t('Worker units');
    case '/work_activity_list':
      return i18n.t('Worker activity list');
    case '/analytics':
      return i18n.t('Revenue/Cost');
    case '/material_cost':
      return i18n.t('Consumption - Cost');
    case '/revenue_cost':
      return i18n.t('Yield - Revenue');
    case '/workers_report':
      return i18n.t('Worker Cost');
    case '/enterprise':
      return i18n.t('List of activities');
    case '/crop_rotation':
      return i18n.t('Crop rotation');
    case '/sectors':
      return i18n.t('Sectors');
    case '/users':
      return i18n.t('Users');
    case '/material_table':
      return i18n.t('Consumption - Cost / table view');
    case '/revenue_table':
      return i18n.t('Yield - Revenue / table view');
    case '/agrodron_report':
      return i18n.t('Drone recording');
    case '/extra_expenses':
      return i18n.t('Extra expenses');
    default:
      return i18n.t('TopBar');
  }
};
