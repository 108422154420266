import React from 'react';
import { Button, IconButton, Menu, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { HorizontalBar, Doughnut, defaults } from 'react-chartjs-2';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import axios from '../../../utils/AxiosWrapper';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#04764e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

const styles = theme => ({
    card: {
        minWidth: 275,
        padding: '10px',
        width: '90%',
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default class StatisticsDashboard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            statisticsItems: [],
            total: null,
            mColors: [], msgColors: [],
            material: [], materialSubgroup: [],
            seasons: [], season: [],
            sectors: [], sector: [],
            crops: [], crop: [],
            fields: [], field: [],
            plans: [], plan: [],
        }
    }

    componentDidMount() {
        this.getChartData([], [], [], []);
        this.getByURL('season/drop_down', 'seasons');
        this.getByURL('sektor/drop_down', 'sectors');
        this.getByURL('crop/drop_down', 'crops');
        this.getByURL('field/drop_down_vlasnik', 'fields');
        let plans = [
            { id: true, naziv: 'Yes' },
            { id: false, naziv: 'No' },
        ];
        this.setState({ plans });
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(err => { console.log('Error: ', err); });
    }

    getChartData(season, sector, crop, field, plan) {
        axios.get('api/report/dashboard_yield_cene', { params: { season: season, sector: sector, crop: crop, field: field, plan: plan } })
            .then(res => {
                this.setState({
                    total: res.data.total,
                    material: res.data.material,
                    materialSubgroup: res.data.materialSubgroup,
                    mColors: randomColor({ count: res.data.material.count }),
                    msgColors: randomColor({ count: res.data.materialSubgroup.count }),
                })
            })
            .catch(err => { console.log('Error: ', err); })
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    onPieClick = () => { this.props.history.push('/yield_dashboard'); }
    onTableClick = () => { this.props.history.push('/yield'); }
    onProfitClick = () => { this.props.history.push('/yield_profit'); }
    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }
    numberWithCommas(x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); }

    render() {
        let materialsColor = [], materialsSubgroupColor = [];
        if (this.state.mColors !== [] && this.state.msgColors !== []) {
            materialsColor = this.state.mColors;
            materialsSubgroupColor = this.state.msgColors;
        };

        let materials = {
            labels: [],
            datasets: [{
                data: [], data1: [], borderWidth: 1,
                label: i18n.t('Revenue'),
                backgroundColor: materialsColor,
                borderColor: materialsColor,
                hoverBackgroundColor: materialsColor,
                hoverBorderColor: materialsColor,
            }],
            count: 0
        };

        let materialsSubgroup = {
            labels: [],
            datasets: [{
                data: [], data1: [], borderWidth: 1,
                label: i18n.t('Revenue'),
                backgroundColor: materialsSubgroupColor,
                borderColor: materialsSubgroupColor,
                hoverBackgroundColor: materialsSubgroupColor,
                hoverBorderColor: materialsSubgroupColor,
            }],
            count: 0
        };

        if (
            this.state.material &&
            this.state.materialSubgroup
        ) {
            materialsSubgroup.labels = this.state.materialSubgroup.labels;
            materialsSubgroup.datasets[0].data = this.state.materialSubgroup.quantity;
            materialsSubgroup.count = this.state.materialSubgroup.count * 2;

            materials.labels = this.state.material.labels;
            materials.datasets[0].data = this.state.material.quantity;
            materials.count = this.state.material.count * 1.2;
        };

        const options = {
            animation: { duration: 0 },
            hover: { animationDuration: 0 },
            responsiveAnimationDuration: 0,
            cutoutPercentage: 40
        };

        let stringTotal = 0;
        if (this.state.total !== null) stringTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(this.state.total));

        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ width: '95%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {this.state.total &&
                                <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Total Revenue')}: {stringTotal} $</h1>
                            }
                        </div>
                    </Card>
                </div>
                <br></br>
                <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div>
                        <InputLabel className="filter-label">{i18n.t('Season')}:</InputLabel>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={this.state.season}
                            components={{ IndicatorsContainer: IndicatorsContainer }}
                            maxMenuHeight={150}
                            onChange={this.handleChange('season')}
                            options={this.state.seasons.map((item) => { return { label: item.naziv, value: item.id } })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>
                    <div>
                        <InputLabel className="filter-label">{i18n.t('Sector')}:</InputLabel>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={this.state.sector}
                            components={{ IndicatorsContainer: IndicatorsContainer }}
                            maxMenuHeight={150}
                            onChange={this.handleChange('sector')}
                            options={this.state.sectors.map((item) => { return { label: item.naziv, value: item.id } })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>
                    <div>
                        <InputLabel className="filter-label">{i18n.t('Crop')}:</InputLabel>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={this.state.crop}
                            components={{ IndicatorsContainer: IndicatorsContainer }}
                            maxMenuHeight={150}
                            onChange={this.handleChange('crop')}
                            options={this.state.crops.map((item) => { return { label: item.naziv, value: item.id } })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>
                    <div>
                        <InputLabel className="filter-label">{i18n.t('Public green area')}:</InputLabel>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={this.state.field}
                            components={{ IndicatorsContainer: IndicatorsContainer }}
                            maxMenuHeight={150}
                            onChange={this.handleChange('field')}
                            options={this.state.fields.map((item) => { return { label: item.naziv, value: item.id } })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>
                    <div>
                        <InputLabel className="filter-label">{i18n.t('Planned')}:</InputLabel>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={this.state.plan}
                            components={{ IndicatorsContainer: IndicatorsContainer }}
                            maxMenuHeight={150}
                            onChange={this.handleChange('plan')}
                            options={this.state.plans.map((item) => { return { label: item.naziv, value: item.id } })}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>
                    <Button
                        onClick={(e) => {
                            let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [];

                            seasonValues = this.getValues(seasonValues, this.state.season);
                            sectorValues = this.getValues(sectorValues, this.state.sector);
                            cropValues = this.getValues(cropValues, this.state.crop);
                            fieldValues = this.getValues(fieldValues, this.state.field);
                            planValues = this.getValues(planValues, this.state.plan);

                            this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues);
                            // this.getProfit(seasonValues, sectorValues, cropValues, fieldValues, planValues);
                        }}
                        style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '10%', marginTop: '32px', marginLeft: '10px' }}>
                        {i18n.t('Submit')}
                    </Button>
                    <Button
                        onClick={(e) => { this.setState({ season: [], sector: [], crop: [], field: [], plan: [] }); }}
                        style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '10%', marginTop: '32px', marginLeft: '20px' }}
                    >
                        {i18n.t('Reset')}
                    </Button>
                </div>
                <br></br>
                {materialsSubgroup.datasets[0].data !== [] && materialsSubgroup.labels !== [] && materialsSubgroup.count > 0 &&
                    <div style={{ width: '95%' }}>
                        <Card style={styles.card}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '-10px' }}>{i18n.t('Revenue by Subgroup')}:</h1>
                            <HorizontalBar height={10} width={100} data={materialsSubgroup} redraw options={options} />
                        </Card>
                    </div>
                }
                <br></br>
                {materials.datasets[0].data !== [] && materials.labels !== [] && materials.count > 0 &&
                    <div style={{ width: '95%' }}>
                        <Card style={styles.card}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '-10px' }}>{i18n.t('Revenue')}: </h1>
                            <HorizontalBar height={20} width={100} data={materials} redraw options={options} />
                        </Card>
                    </div>
                }
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
            </div >
        )
    }
}