import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
export default class AddWorkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            first_name: '',
            last_name: '',
            sifra: '',
            workplace: '', workplaces: [],
            category: '',
            // worker_group: '', worker_groups: [],
            sector: '', sectors: [],
            cena: null,
            polj_radovi: false,
        }
    }

    componentDidMount() {
        this.getByURL('workplace/drop_down', 'workplaces');
        // this.getByURL('worker/filter_worker_group', 'worker_groups');
        this.getByURL('worker/filter_sector', 'sectors');

        if (this.props.edit === true) {
            this.setState({
                first_name: this.props.data.ime,
                last_name: this.props.data.prezime,
                sifra: this.props.data.sifra,
                workplace: this.props.data.id_radno_mesto,
                category: this.props.data.kategorija,
                // worker_group: this.props.data.id_radnik_grupa,
                sector: this.props.data.id_sektor,
                polj_radovi: this.props.data.polj_radovi,
                // cena: this.props.data.cena,
            })
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addWorkers() {
        if (
            this.state.first_name
            && this.state.last_name
            && this.state.workplace
            // && this.state.worker_group
            // && this.state.sector
        ) {
            let data = {
                //id: this.props.data.id, 
                ime: this.state.first_name,
                prezime: this.state.last_name,
                sifra: this.state.sifra,
                id_radno_mesto: Number(this.state.workplace),
                kategorija: this.state.category,
                id_radnik_grupa: Number(this.state.worker_group),
                id_sektor: this.state.sector ? Number(this.state.sector) : null,
                polj_radovi: this.state.polj_radovi,
                // cena: this.state.cena,
            }

            if (this.props.data) {
                data.id = this.props.data.id;// da prepozna za edit na backendu
            }
            
            axios.post('api/worker/create', { data: data })
                .then(res => {
                    this.props.handleCreate();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    onSwitchClick = () => { this.setState({ polj_radovi: !this.state.polj_radovi }); }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Worker') : i18n.t('Add Worker')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={6}>
                                    <TextField required
                                        label={i18n.t('First name')}
                                        value={this.state.first_name}
                                        style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ first_name: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid xs={6}>
                                    <TextField required
                                        label={i18n.t('Last name')}
                                        value={this.state.last_name}
                                        style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ last_name: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        label={i18n.t('Code')}
                                        value={this.state.sifra}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ sifra: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Workplace')}
                                        select
                                        value={this.state.workplace}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ workplace: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.workplaces.map((workplaces) => <option key={workplaces.id} value={workplaces.id}>{workplaces.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        label={i18n.t('Category')}
                                        value={this.state.category}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ category: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                {/* 
                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Worker group')}
                                        select
                                        value={this.state.worker_group}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ worker_group: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.worker_groups.map((worker_groups) => <option key={worker_groups.id} value={worker_groups.id}>{worker_groups.naziv}</option>)}
                                    </TextField>
                                </Grid> */}

                                {/* <Grid item xs={6}>
                                    <TextField
                                        // required
                                        label={i18n.t('Sector')}
                                        select
                                        value={this.state.sector}
                                        SelectProps={{ native: true }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ sector: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.sectors.map((sectors) => <option key={sectors.id} value={sectors.id}>{sectors.naziv}</option>)}
                                    </TextField>
                                </Grid> */}
                                {/* 
                                <Grid xs={6}>
                                    <TextField
                                        label={i18n.t('Price')}
                                        value={this.state.cena}
                                        type="number"
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ cena: e.target.value }); }} >
                                    </TextField>
                                </Grid> */}

                                {/* <FormControlLabel
                                    style={{}}
                                    control={<Switch
                                        checked={this.state.polj_radovi}
                                        onChange={() => this.onSwitchClick()}
                                        name="checkedB"
                                        color="primary" />}
                                    label={i18n.t('Agricultural worker')} */}
                                {/* /> */}

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addWorkers()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};
