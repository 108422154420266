import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Button, TextField, Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import { getDistribution, areaChanged } from '../../actions/activity/ActivityActions';
import { toast } from 'react-toastify';
import _ from 'lodash';

const styles = theme => ({
    innerContainer: {
        width: '100%',
        marginTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
    },
    card: {
        width: 450,
        height: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        marginTop: 24
    },
    innerContainerTable: {
        width: '37%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    button: {
        alignSelf: 'flex-start',
        height: 40,
        color: 'white',
    },
    buttonsRow: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    distText: {
        fontSize: 15,
        marginBottom: 2,
    },
    headerText: {
        fontSize: 16,
        alignSelf: 'center',
        color: 'white'
    },
    listSectionHeader: {
        backgroundColor: theme.palette.secondary.dark,
        margin: 10,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 5
    },
});
export class MaterialDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDistribution: false,
            distribution: []
        }
    }

    componentDidUpdate(prevProps) {
        const { distribution } = this.props;
        if (prevProps.distribution !== distribution) {
            this.setState({
                distribution: distribution,
            })
        }
    }

    showDistributionModal = () => {
        this.setState({
            showDistribution: !this.state.showDistribution
        }, () => {
            if (this.state.showDistribution === true) {
                const { id, getDistribution } = this.props;
                getDistribution(id);
            }
        })
    }

    componentDidMount() {
        const { viewOnly } = this.props;
        if (viewOnly) {
            this.setState({
                viewOnly
            })
        }
    }

    onAreaChanged(text, index, item) {
        const { id } = this.props;
        let zaRaspodelu = this.state.distribution.filter((row) => row.id_materijal == item.id_materijal);
        if (text !== item.obradjena_povrsina) {
            if (item.povrsina < text) {
                this.props.getDistribution(id);
                toast.error(i18n.t('Effect can\'t be larger than land area!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                }));
            } else {
                this.props.areaChanged(index, parseFloat(text), item, zaRaspodelu);
            }
        }
    }

    renderItem(item, index, separators) {
        const { classes } = this.props;
        const { viewOnly } = this.state;
        if (item.header) {
            return <div>
                <div className={classes.listSectionHeader}>
                    <Typography className={classes.headerText}> {item.materijal} </Typography>
                </div>
                <div>
                    <table>
                        <tr>
                            <td>{i18n.t('Public green area')}: {item.tabla}</td>
                            <td>{i18n.t('Effect')}: {item.obradjena_povrsina}</td>
                            {item.utroseno != null ? <td>{i18n.t('Spent')}: {item.utroseno}</td> : <td>{i18n.t('Yield')}: {item.prinos}</td>}
                        </tr>
                    </table>
                </div>
            </div>
        }

    }



    render() {
        const { classes, theme, distributionFetching, distribution } = this.props;
        let content;
        let unique = [];
        if (distributionFetching) {
            content = <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '20%', fontSize: 20, fontWeight: 'bold' }}>{i18n.t('Loading...')}</div>
        }
        else if (distribution.length > 0) {
            let array = distribution.map(item => item.materijal);
            unique = [...new Set(array)];
        }
        else if (distribution.length === 0) {
            content = <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10%', fontSize: 20, fontWeight: 'bold' }}>{i18n.t('Empty distribution!')}</div>
        }
        return (
            <div className={classes.innerContainer}>
                {this.state.showDistribution &&
                    <Dialog
                        open={this.state.showDistribution}
                        onClose={this.showDistributionModal}
                        maxWidth="sm"
                        disableBackdropClick
                        scroll="paper"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle style={{ background: theme.palette.secondary.dark }} disableTypography={true} className="responsive-dialog-title">{i18n.t('Material distribution by fields')}
                            <IconButton onClick={this.showDistributionModal}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Card raised className={classes.card}>
                                {distribution.length > 0 && unique.map((item, i) => {
                                    let array = distribution.filter(el => el.materijal === item);

                                    return <div>
                                        <div className={classes.listSectionHeader}>
                                            <Typography className={classes.headerText}> {item} </Typography>
                                        </div>
                                        <table style={{ width: '95%', marginLeft: '10px', marginRight: '10px' }}>
                                            <div></div>
                                            <tr>
                                                <th style={{ width: '30%' }}>{i18n.t('Public green area')}</th>
                                                <th style={{ width: '30%' }}>{i18n.t('Land area')}</th>
                                                {array[0].utroseno !== null ? <th style={{ width: '30%' }}>{i18n.t('Spent')}</th> : <th style={{ width: '30%' }}>{i18n.t('Yield')}</th>}
                                            </tr>

                                            {array.map((el, e) => {
                                                return <tr>
                                                    <td style={{ width: '30%', textAlign: '-webkit-center' }}>{el.tabla}</td>
                                                    <td style={{ width: '30%', textAlign: '-webkit-center' }}>{el.obradjena_povrsina}</td>
                                                    {el.utroseno != null ? <td style={{ width: '30%', textAlign: '-webkit-center' }}>{el.utroseno}</td> : <td style={{ width: '30%', textAlign: '-webkit-center' }}>{el.prinos}</td>}
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                })}

                                {distribution.length === 0 && <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10%', fontSize: 20, fontWeight: 'bold' }}>{i18n.t('Empty distribution!')}</div>}
                                <div className={classes.buttonsRow}>
                                </div>
                            </Card>
                        </DialogContent>
                    </Dialog>
                }
                <Button variant="contained" className={classes.button} style={{ backgroundColor: theme.palette.primary.main }} onClick={this.showDistributionModal}>
                    {i18n.t('See distribution')}
                </Button>
            </div >
        )

    }

}


function mapStateToProps(state) {
    return {
        distribution: state.activityReducer.distribution,
        distributionFetching: state.activityReducer.distributionFetching,
        distributionFetchingFailed: state.activityReducer.distributionFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDistribution: (id) => dispatch(getDistribution(id)),
        areaChanged: (index, text, item, zaRaspodelu) => dispatch(areaChanged(index, text, item, zaRaspodelu)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialDistribution))