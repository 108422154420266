import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#04764e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

export default class DashboardFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = { seasons: [], sectors: [], crops: [], fields: [], plans: [], operations: [] }
    }

    componentDidMount() {
        this.getByURL('season/drop_down', 'seasons');
        this.getByURL('sektor/drop_down', 'sectors');
        this.getByURL('crop/drop_down_vlasnik', 'crops');
        this.getByURL('field/drop_down_vlasnik', 'fields');
        this.getByURL('working_operation/klijent_drop_down', 'operations');
        let plans = [
            { id: true, naziv: 'Yes' },
            { id: false, naziv: 'No' },
        ];
        this.setState({ plans });
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(err => { console.log('Error: ', err); });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <FilterSelect string={i18n.t('Season')} value={this.props.season} onChange={this.props.handleChange('season')} options={this.state.seasons} />
                    <FilterSelect string={i18n.t('Sector')} value={this.props.sector} onChange={this.props.handleChange('sector')} options={this.state.sectors} />
                    <FilterSelect string={i18n.t('Crop')} value={this.props.crop} onChange={this.props.handleChange('crop')} options={this.state.crops} />
                    <FilterSelect string={i18n.t('Public green area')} value={this.props.field} onChange={this.props.handleChange('field')} options={this.state.fields} />
                    {this.props.operation_visible === true ? <FilterSelect string={i18n.t('Operation')} value={this.props.operation} onChange={this.props.handleChange('operation')} options={this.state.operations} /> : null}
                    {this.props.planned_invisible === false ? null : <FilterSelect string={i18n.t('Planned')} value={this.props.plan} onChange={this.props.handleChange('plan')} options={this.state.plans} />}
                </div>
            </div >
        )
    }
}

function FilterSelect(props) {
    return <div>
        <InputLabel className="filter-label">‏‏‎  ‎‏‏‎ ‎‏‏‎ ‎{props.string}:</InputLabel>
        <Select
            isMulti
            closeMenuOnSelect={false}
            isClearable={false}
            value={props.value}
            components={{ IndicatorsContainer: IndicatorsContainer }}
            maxMenuHeight={200}
            onChange={props.onChange}
            options={props.options.map((item) => { return { label: item.naziv, value: item.id } })}
            className="multi-select-in-filter-dashboards"
            classNamePrefix="select"
            placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select')}
        />
    </div>
}