import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { login, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    primaryMain: {
        backgroundColor: '#126038',
    },
});

export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.logInFailed === true) {
            if (nextProps.message == 'password') {
                toast.error(i18n.t('Incorrect password!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80,
                    theme: "light"
                });
            } else if (nextProps.message == 'Parklife') {
                toast.error(i18n.t('Only ParkLife can log in here!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            } else {
                toast.error(i18n.t('Username or password are incorrect!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    draggablePercent: 80
                });
            }
            this.props.setInitialState('logInFailed');
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.loginPressed()
        }
    }

    toastError() {
        toast.error(i18n.t('Username or password are incorrect!'), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 80
        });
    }

    loginPressed() {
        if (this.state.username && this.state.password) {
            this.props.login(this.state.username, this.state.password)
        } else {
            toast.error(i18n.t('Username and passwords are required.'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    render() {
        return (
            <div className="login">
                <div className="loginContainer">
                    <div className="logo">
                        <img src={require('../../assets/images/login_ParkLife.svg')} width="200" height="50" />
                    </div>
                    <div>
                      <p style={{ fontSize: 16, fontWeight: 500 }}>{i18n.t('login')}</p>
                    </div>
                    <TextField
                        label={i18n.t('Username')}
                        required
                        style={{ width: '260px' }}
                        onChange={(event) => this.setState({ username: event.target.value })}
                    />
                    <br />
                    <TextField
                        type="password"
                        label={i18n.t('Password')}
                        required
                        style={{ width: '260px' }}
                        onChange={(event) => this.setState({ password: event.target.value })}
                    />
                    <br />
                    <Link
                        onClick={() => this.props.history.push('forgotpass')}
                        style={{ color: '#000', marginTop: 10 }}
                    >
                        {i18n.t('Forgot password?')}
                    </Link>
                    <br />
                    <Button
                        className="loginButton"
                        variant="contained"
                        style={{ backgroundColor: '#04764e', color: 'white', textTransform: 'none' }}
                        onClick={() => this.loginPressed()}
                    >
                        {i18n.t('login')}
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        logInFailed: state.appReducer.logInFailed,
        token: state.appReducer.token,
        message: state.appReducer.message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => dispatch(login(username, password)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage));