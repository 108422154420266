import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import { getNoteGroupDropDown, getFieldNoteById, getNoteDocument, getNoteReport, setInitialState, deleteNote } from '../../actions/note/NotesActions';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Button, IconButton, Tooltip, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { exportDocument } from '../../actions/ExportActions';
import { Export } from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';
import AddNote from './AddNote';
import { Delete } from '../../utils/Delete';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import * as styles from "./Note.module.css";
import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';
import { DateRange } from 'react-date-range';
import axios from './../../utils/AxiosWrapper';
import { wentWrong, wentRight } from '../../utils/ToastConfig';
import * as Icons from '@material-ui/icons';
import ReactPaginate from "react-paginate";

var FileSaver = require('file-saver');
var XLSX = require('xlsx');


const CheckboxTable = checkboxHOC(ReactTable);;

export class Note extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selection: [],
            selectAll: false,
            add: false,
            edit: false,
            delete: false,

            // --------------------
            anchorDatePicker: null,
            selectedDateRange: {
                endDate: null,
                selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                },
                startDate: null,
            },
            allNotes: [],
            filteredNotes: [],
            noteTypes: [],
            selectedNoteType: "",
            deleteWindow: false,
            addNewNote: false,
            editNote: false,
            itemForEditing: null,

            searchBarValue: "",


            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 7,
            //export feature
            exportWindow: false,
            docTitle: null
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        this.fetchAllNotes();


        const storedNumber = sessionStorage.getItem('pageNumberNotes');
        if (storedNumber) {
            this.setState({ currentPage: Number(storedNumber) });
        } else {
            this.setState({ currentPage: 0 });
        }

        this.setupPageCount();

        const { noteGroupDropDown, getNoteGroupDropDown } = this.props;
        if (noteGroupDropDown.length === 0) {
            getNoteGroupDropDown();
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.allNotes !== this.state.allNotes) {
            this.populateDropdowns();
        }

        if (prevState.selectedDateRange !== this.state.selectedDateRange ||
            prevState.selectedNoteType !== this.state.selectedNoteType ||
            prevState.searchBarValue !== this.state.searchBarValue
        ) {
            this.updateFilteredNotes();
        }

        if (prevState.filteredNotes !== this.state.filteredNotes) {
            this.setupPageCount();
        }

        // console.log('aaaaaaa', prevProps)
        const { notesPostingSuccess, noteGroupDropDown, getNoteReport, setInitialState, deleted } = this.props;
        if (!prevProps.notesPostingSuccess && notesPostingSuccess) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            getNoteReport(wrapped.state);
            setInitialState('notesPostingSuccess');
        }

        if (!prevProps.deleted && deleted) {
            // console.log('1111')
            const wrapped = this.checkboxTable.getWrappedInstance();
            getNoteReport(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            setInitialState('notesObj');
            setInitialState('deleted');
        }
        if (prevProps.noteGroupDropDown !== noteGroupDropDown) {
            this.setState({
                noteGroupDropDown: immutable.asMutable(noteGroupDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
    }

    handleClick = () => {
        const { edit, add, selection } = this.state;
        const { setInitialState } = this.props;
        if (edit == true && add == false) {
            this.setState({ edit: false })
            setInitialState('notesObj');
        } else if (selection.length > 0 && add == false && edit == false) {
            setInitialState('notesObj');
        }
        this.setState({ add: !add }, () => {
            if (add == true && edit == false) {
                this.setState({
                    selection: [],
                    selectAll: false,
                })
            }
        });
    };

    onEditClick = () => {
        const { getFieldNoteById, getNoteDocument } = this.props;
        const { selection } = this.state;
        getFieldNoteById(selection);
        this.setState({ add: !this.state.add, edit: true }, () => {
            getNoteDocument(selection)
        });
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }


    onExportClick = () => { 
        //NE POZIVA SE
        this.setState({ export: !this.state.export }); };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        console.log("this.state.selection : ", this.state.selection)
        this.props.deleteNote(this.state.selection);
        this.setState({ delete: false })
    }

    /*
    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/field_note');
        this.setState({ export: !this.state.export });
    }*/

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredNotes);
        this.setState({ exportWindow: false });
    }

    onPieClick = () => {
        this.props.history.push('/notes_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/notes');
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };


    // ----------------------------------

    fetchAllNotes = () => {
        axios.get('api/note/get_all_by_client')
            .then(res => {
                this.setState({ allNotes: res.data.data }, () => {
                    console.log("ALL NOTES : ", this.state.allNotes)
                });
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    handleChangeDate = (selectedDate) => {
        this.setState({
            selectedDateRange: selectedDate.range1,
        });
    }

    handleDatePickerClick = (e) => {
        this.setState({ anchorDatePicker: e.currentTarget });
    };

    handleDatePickerClose = () => {
        this.setState({ anchorDatePicker: null });
    };

    populateDropdowns = () => {
        let noteTypes = new Set(this.state.allNotes && this.state.allNotes.map(item => item.vrsta_terenske_beleske));

        this.setState({
            noteTypes: [...noteTypes],
            filteredNotes: this.state.allNotes,
        }, () => {
            this.updateFiltersFromSessionStorage();

        });
    }

    handleChangeSelectedNote = (e) => {
        this.setState({ selectedNoteType: e.target.value })
    }

    updateFilteredNotes = () => {
        this.setState({
            filteredNotes: this.state.allNotes
                .filter(item => this.state.selectedNoteType === "" ? item : item.vrsta_terenske_beleske === this.state.selectedNoteType)
                .filter(item => item.naslov === "" ? item : item.naslov.toLowerCase().includes(this.state.searchBarValue.toLocaleLowerCase()))
                .filter((item) => {
                    if (this.state.selectedDateRange.startDate === null) {
                        return item;
                    }
                    if (this.parseDate(item.datum_dogadjaja).getTime() >= new Date(this.state.selectedDateRange.startDate.toString().slice(4, 15)).getTime() && this.parseDate(item.datum_dogadjaja).getTime() <= new Date(this.state.selectedDateRange.endDate.toString().slice(4, 15)).getTime()) {
                        return item;
                    }
                })
        }, () => {
            this.setupPageCount();
        });

        const preparedForSaving = JSON.stringify({
            selectedNoteType: this.state.selectedNoteType,
            selectedDateRange: this.state.selectedDateRange,
            searchBarValue: this.state.searchBarValue,
        })

        sessionStorage.setItem('preservedFilterValuesNotes', preparedForSaving);
    }

    updateFiltersFromSessionStorage = () => {
        const storedFilterValues = sessionStorage.getItem('preservedFilterValuesNotes');

        if (storedFilterValues) {
            const parsedFilterValues = JSON.parse(storedFilterValues);

            this.setState({
                selectedNoteType: this.state.noteTypes.includes(parsedFilterValues.selectedNoteType) ? parsedFilterValues.selectedNoteType : "",
                selectedDateRange: {
                    endDate: parsedFilterValues.selectedDateRange.endDate ? new Date(String(parsedFilterValues.selectedDateRange.endDate)) : null,
                    selection: {
                        startDate: parsedFilterValues.selectedDateRange.selection.startDate,
                        endDate: parsedFilterValues.selectedDateRange.selection.endDate,
                        key: parsedFilterValues.selectedDateRange.selection.key,
                    },
                    startDate: parsedFilterValues.selectedDateRange.startDate ? new Date(String(parsedFilterValues.selectedDateRange.startDate)) : null,
                },
                searchBarValue: parsedFilterValues.searchBarValue,
            });

        }
    }

    handleDeleteClick = (id) => {
        if (!this.state.deleteWindow) {
            this.setState({ idForDeletion: id }, () => { this.setState({ deleteWindow: true }) })
        } else { this.setState({ deleteWindow: false }) }

    }

    deleteSelectedItem = () => {
        axios.post(`api/note/delete`, { selection: [Number(this.state.idForDeletion)] })
            .then(() => {
                this.setState({ deleteWindow: false }, () => this.fetchAllNotes())
                wentRight(i18n.t('You successfully deleted a note'))
            })
            .catch(() => {
                this.setState({ deleteWindow: false })
                wentWrong(i18n.t('Something went wrong'))
            });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('pageNumberNotes', pageNum.selected);
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredNotes.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    parseDate = (dateString) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return new Date(year, month - 1, day);
    };

    resetMyFilters = () => {
        this.setState(
            {
                selectedNoteType: "",
                selectedDateRange: {
                    startDate: null,
                    endDate: null,
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                },
                searchBarValue: "",
                currentPage: 0,
            })
    }

    handleCloseAddNew = () => {
        this.setState({ addNewNote: false }, () => {
            this.fetchAllNotes();
        })
    }

    handleEditClick = (item) => {
        this.setState({ itemForEditing: item }, () => this.setState({ editNote: true }))
    }

    handleCloseEdit = () => {
        this.setState({ editNote: false }, () => this.fetchAllNotes())
    }

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }
    
    async exportDocument(title, data) {

        let newData = data.map(item => {
            return {
                Naziv: item.naslov,
                Datum: item.datum_dogadjaja,
                Vrsta_terenske_beleške: item.vrsta_terenske_beleske,
                Korisnik: item.korisnik_ime_prezime,
                Opis: item.opis ? item.opis : "",
            }
        });

        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 200 },
                { wpx: 100 },
                { wpx: 200 },
                { wpx: 120 },
                { wpx: 200 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Terenske beleške');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredNotes);
        this.setState({ exportWindow: false });
    }

    render() {
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'naslov',
            },
            {
                Header: i18n.t('Date of the event'),
                accessor: 'datum_dogadjaja',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                Header: i18n.t('Notes type'),
                accessor: 'naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.noteGroupDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />

            },
            {
                Header: i18n.t('Name'),
                accessor: 'ime',
            },
            {
                Header: i18n.t('Surname'),
                accessor: 'prezime',
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis',
            }

        ];
        const { toggleSelection, toggleAll, isSelected, handleClick } = this;
        const { selectAll, add, edit } = this.state;
        const { theme } = this.props;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: '#58B4E5'
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className={styles.searchWrap}>
                    <SearchBar
                        className={styles.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        // onChange={(searchValue) => !searchValue && this.handleSearchBar('')}
                        // onRequestSearch={(searchValue) => this.handleSearchBar(searchValue)}
                        // onCancelSearch={() => this.handleSearchBar('')}
                        onChange={(searchValue) => this.handleSearchBar(searchValue)}
                        onCancelSearch={() => this.handleSearchBar('')}
                    />

                    {/* <Button
                        variant="fab"
                        color="secondary"
                        aria-label="Export"
                        className={styles.addNewBtn}
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow }, () => {
                            console.log("this.state.exportWindow : ", this.state.exportWindow)
                        })}
                    >
                        {i18n.t('Export')}
                    </Button> */}

                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={styles.addNewBtn}
                        onClick={() => { this.setState({ addNewNote: true }) }}
                    >
                        <AddIcon />
                        {i18n.t('Add field note')}
                    </Button>

                </div>

                <div className={styles.filtersSection}>
                    <select className={styles.dropDowns} value={this.state.selectedNoteType} onChange={this.handleChangeSelectedNote} name="status" id="status">
                        <option value="">{i18n.t('Note type')}</option>
                        {this.state.noteTypes && this.state.noteTypes.map((note, i) => (
                            <option key={i} value={note}>{note}</option>
                        ))}
                    </select>
                    <Tooltip title={i18n.t('Date')}>
                        <IconButton aria-label="filter"
                            aria-owns={this.state.anchorDatePicker ? 'datePicker' : null}
                            aria-haspopup="true"
                            className={styles.dateSelectBtn}
                            onClick={this.handleDatePickerClick}>
                            <CalendarMonthIcon />
                            <span className={styles.dateSelectText}>{i18n.t('Date')}</span>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="datePicker"
                        anchorEl={this.state.anchorDatePicker}
                        open={Boolean(this.state.anchorDatePicker)}
                        onClose={this.handleDatePickerClose}
                    >
                        {/* <InputLabel className={styles.filterLabel}> */}
                        {/* {i18n.t('Date')}: */}
                        {/* </InputLabel> */}
                        <div className={styles.calendarTitle}>{i18n.t('Date')}:</div>
                        <DateRange
                            ranges={[this.state.selectedDateRange]}
                            // moveRangeOnFirstSelection={false}
                            className={styles.calendar}
                            onChange={this.handleChangeDate}
                            preview={{ color: theme.palette.primary.main }}
                        />
                    </Menu>
                    {/* <select className={styles.dropDowns} value={this.state.selectedTable} onChange={this.handleChangeTable} name="table" id="table">
                        <option value="">{i18n.t('Public green areas')}</option>
                        {this.state.tables && this.state.tables.map((table, i) => (
                            <option key={i} value={table}>{table}</option>
                        ))}
                    </select>
                    <select className={styles.dropDowns} value={this.state.selectedOperation} onChange={this.handleChangeOperation} name="operations" id="operations">
                        <option value="">{i18n.t('Working operations')}</option>
                        {this.state.operations && this.state.operations.map((operation, i) => (
                            <option key={i} value={operation}>{operation}</option>
                        ))}
                    </select> */}
                    <button className={styles.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>

                    <Button
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className={styles.exportButton}
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                    {/* <Button
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className={styles.exportButton}
                    // style={{ marginLeft: 'auto !important' }}

                    >
                        <span className="icon-download headerIcon" />
                    </Button> */}
                </div>

                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>
                    <div className={styles.tableHeader}>
                        <div className={styles.tableHeaderTitle} >{i18n.t("All notes")}</div>
                    </div>

                    <div style={{ width:'98%', display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                        {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Number")}</div> */}
                        <div style={{ width: '20%', marginRight: "20px" }}>{i18n.t("Title")}</div>
                        <div style={{ width: '10%', marginRight: "20px" }}>{i18n.t("Date")}</div>
                        <div style={{ width: '20%', marginRight: "20px" }}>{i18n.t("Notes type")}</div>
                        <div style={{ width: '10%', marginRight: "20px" }}>{i18n.t("User")}</div>
                        <div style={{ width: '30%', marginRight: "20px" }}>{i18n.t("Description")}</div>
                        <div style={{
                            width: '117px',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Edit")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Delete")}</div>
                        </div>
                    </div>


                    {this.state.filteredNotes && this.state.filteredNotes.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                        <div key={i} style={{ width:'98%', display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2' }}>
                            {/* <div style={{ width: '60px', marginRight: "20px", color: '#9b9658', fontSize: '14px', fontWeight: 'bold' }}>{item.id}</div> */}
                            <div style={{ width: '20%', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.naslov}</div>
                            <div style={{ width: '10%', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.datum_dogadjaja}</div>
                            <div style={{ width: '20%', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.vrsta_terenske_beleske}</div>
                            <div style={{ width: '10%', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.korisnik_ime_prezime}</div>
                            <div style={{ width: '30%', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.opis}</div>
                            

                            <div style={{ display: 'flex', paddingRight: '5px' }}>
                                {/* <Tooltip title={i18n.t("Preview")}>
                                    <IconButton aria-label="preview"
                                        style={{ transform: 'translateX(-11px)' }}
                                        // color="secondary"
                                        onClick={() => this.onVisibilityClick(item.id)}>
                                        <Icons.Visibility fontSize="large" />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title={i18n.t("Edit")}>
                                    <IconButton aria-label="edit"
                                        // color="secondary"
                                        style={{ transform: 'translateX(-5px)' }}
                                        onClick={() => this.handleEditClick(item)}>
                                        <Icons.Edit fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title={i18n.t("Copy")}>
                                    <IconButton aria-label="copy"
                                        // color="secondary"
                                        onClick={() => this.onCopyClick(item.id)}>
                                        <Icons.FileCopy fontSize="large" />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        // className={classes.cssRoot}
                                        onClick={() => this.handleDeleteClick(item.id)}>
                                        <Icons.DeleteOutline fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    ))}

                    <ReactPaginate
                        pageCount={this.state.pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={styles.paginationContainer}
                        activeClassName={styles.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div>


                {this.state.deleteWindow && <Delete delete={this.state.deleteWindow} handleClose={this.handleDeleteClick} deleteItem={this.deleteSelectedItem} />}
                {this.state.addNewNote && <AddNote add={this.state.addNewNote} handleClose={this.handleCloseAddNew} edit={false} />}
                {this.state.editNote && <AddNote add={this.state.editNote} handleClose={this.handleCloseEdit} edit={this.state.editNote} data={this.state.itemForEditing} />}
                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    //remembers old title of excel file
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    // handleClose={() => this.setState({ exportWindow: !this.state.exportWindow })}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />
                }
               
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        noteReport: state.noteReducer.noteReport,
        noteReportFetching: state.noteReducer.noteReportFetching,
        noteReportPages: state.noteReducer.noteReportPages,
        noteGroupDropDown: state.noteReducer.noteGroupDropDown,
        noteGroupDropDownFetching: state.noteReducer.noteGroupDropDownFetching,
        noteGroupDropDownFetchingFailed: state.noteReducer.noteGroupDropDownFetchingFailed,
        notesPostingSuccess: state.noteReducer.notesPostingSuccess,
        deleted: state.noteReducer.deleted,
        notesObj: state.noteReducer.notesObj

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNoteReport: (state, instance) => dispatch(getNoteReport(state, instance)),
        getNoteGroupDropDown: () => dispatch(getNoteGroupDropDown()),
        //exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getFieldNoteById: (id) => dispatch(getFieldNoteById(id)),
        getNoteDocument: (id) => dispatch(getNoteDocument(id)),
        deleteNote: (selection) => dispatch(deleteNote(selection)),

    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Note));