import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import ReactSelect from './../../utils/ReactSelect';
import NumberRangeForFilter from '../../utils/NumberRangeForFilter';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import DashboardDrawer from '../../utils/DashboardDrawer';
import { withStyles } from '@material-ui/core/styles';
import { Export } from '../../utils/Export';
import moment from 'moment';

var FileSaver = require('file-saver');
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });

export class MaterialStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonType: [], sectorType: [], cropType: [], fieldType: [], statusType: [], woType: [], materialType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            totalPages: 0, totalData: 0,
            data: [], docTitle: null, user_season: null, export: false,
            season: null, seasons: [],
        };
    }

    componentDidMount() {
        axios.get('api/season/drop_down')
            .then(res => {
                this.setState({ seasonType: res.data.data }, () => {
                    axios.get('api/season/activity_season')
                        .then(res => this.setState({ user_season: res.data.data[0].id_default_sezona, loading: false }, () => {
                            this.getByURL('sektor/drop_down', 'sectorType');
                            this.getByURL('crop/kultura_klijent', 'cropType');
                            this.getByURL('material/materijal_klijent', 'materialType');
                            this.getByURL('field/drop_down_activity', 'fieldType');
                            this.getByURL('vrsta_proizvodnje/drop_down', 'statusType');
                            this.getByURL('working_operation/radna_operacija_klijent', 'woType');
                        }))
                        .catch(err => { console.log(err) })
                })
            })
            .catch(err => { console.log(err) })
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalData: res.data.total,
                }, () => {
                    if (this.state.user_season !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    exportFunction = () => {
        const table = this.reactTable;
        this.exportDocument(this.state.docTitle, table.state, 'api/report/statistic');
        this.setState({ export: !this.state.export });
    };

    exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        return axios({ url: url, method: 'GET', responseType: 'blob', params: params })
            .then(response => FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`))
            .catch(err => console.log(err))
    };

    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onExportClick = () => this.setState({ export: !this.state.export });
    onProfitClick = () => this.props.history.push('/material_cost');
    onTableClick = () => this.props.history.push('/material_table');
    onPieClick = () => this.props.history.push('/material_used');

    render() {
        let { theme } = this.props;
        let seasonType = this.state.seasonType.map((item) => { return { label: item.naziv, value: item.id } });
        let sectorType = this.state.sectorType.map((item) => { return { label: item.naziv, value: item.id } });
        let materialType = this.state.materialType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropType = this.state.cropType.map((item) => { return { label: item.naziv, value: item.id } });
        let fieldType = this.state.fieldType.map((item) => { return { label: item.naziv, value: item.id } });
        let statusType = this.state.statusType.map((item) => { return { label: item.naziv, value: item.id } });
        let woType = this.state.woType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasons = seasonType.map(item => item.value);

        const columns = [
            { Header: i18n.t('Activity'), accessor: 'id_radni_nalog' },
            { Header: i18n.t('Working operation'), accessor: 'radna_operacija_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={woType} /> },
            { Header: i18n.t('Crop'), accessor: 'kultura_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropType} /> },
            { Header: i18n.t('Material'), accessor: 'materijal_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={materialType} /> },
            { Header: i18n.t('Public green area'), accessor: 'tabla', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={fieldType} /> },
            { Header: i18n.t('Arranged area'), accessor: 'povrsina', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Cost per ha'), accessor: 'cena_po_ha', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Price per unit'), accessor: 'cena_po_jm', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Consumption'), accessor: 'utroseno', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Total cost'), accessor: 'cena', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Sector'), accessor: 'sektor', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={sectorType} /> },
            { Header: i18n.t('Public green areas status'), accessor: 'naziv_proizvodnje', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={statusType} /> },
            {
                Header: i18n.t('Season'), accessor: 'sezona',
                Filter: ({ filter, onChange }) => {
                    return <ReactSelect
                        filter
                        isMulti
                        name="label"
                        onChange={value => { onChange(value) }}
                        defaultValue={seasonType.filter((element) => {
                            if (seasons.includes(this.state.user_season)) return element.value === this.state.user_season;
                        })}
                        closeMenuOnSelect={false}
                        options={seasonType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                }
            },
            { id: 'deo_table', Header: i18n.t('Part of the field'), accessor: d => d.deo_table, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            { id: 'datum_pocetka', Header: i18n.t('Start date'), accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '', Filter: ({ filter, onChange }) => <div style={{}}> <DatePickerForFilter onChange={({ startDate, endDate }) => onChange({ startDate, endDate })} /> </div> },
            { id: 'datum_zavrsetka', Header: i18n.t('End date'), accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '', Filter: ({ filter, onChange }) => <div style={{}}>  <DatePickerForFilter onChange={({ startDate, endDate }) => onChange({ startDate, endDate })} />  </div> },
        ];
        const url = 'report/statistic';

        const checkboxProps = {
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) { return { style: { backgroundColor: "inherit", alignItems: 'flex-end' } }; }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#4da7df' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered: [
                {
                    id: 'sezona',
                    value: seasonType.filter((element) => {
                        if (seasons.includes(this.state.user_season)) return element.value === this.state.user_season;
                    })
                }
            ]
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-download headerIcon" />
                    </Fab>
                </div>
                <ReactTable
                    ref={r => (this.reactTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (<button {...props} className='-btn' style={{ backgroundColor: theme.palette.primary.main, color: '#FFFFFF' }}>{props.children}</button>)}
                    NextComponent={(props) => (<button {...props} className='-btn' style={{ backgroundColor: theme.palette.primary.main, color: '#FFFFFF' }}>{props.children}</button>)}
                />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MaterialStatistics);

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}