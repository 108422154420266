import React from 'react';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { toggleDrawer, toggleMenu, logout, getUserProfile, getClientProfile } from '../actions/LoginActions';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    MenuItem, ListItemText, ExpansionPanel,
    ExpansionPanelSummary, ExpansionPanelDetails, Typography
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { withRouter } from 'react-router-dom'
import i18n from '../i18n/i18n';
import Support from './Support';
import classnames from 'classnames';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MoneyIcon from '@material-ui/icons/Money';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const Paper = styled('aside')`
  position: fixed;
  z-index: ${p => p.theme.zIndex.drawer};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  background: ${p => p.theme.palette.secondary.main};
  transition: width 0.2s ease-in-out;
  box-shadow:  1px 0 2px rgba(0,0,0,0.07), 
                 2px 0 4px rgba(0,0,0,0.11), 
                 4px 0 8px rgba(0,0,0,0.14), 
                 8px 0 16px rgba(0,0,0,0.14);
  border-radius: 0px;
`;

// height: ${p => p.theme.size(4)};
const Header = styled('header')`
  display: flex;
  align-items: center;
  height: 67px;
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  transition: width 0.2s ease-in-out;
  background: ${p => (p.isDrawerOpen || p.isMenuOpen ? p.theme.palette.secondary.dark : p.theme.palette.secondary.dark)};
`;

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    secondaryMain: {
        backgroundColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
    },
    secondaryDark: {
        backgroundColor: theme.palette.secondary.dark,
        background: theme.palette.secondary.dark,
    },
    secondaryLight: {
        backgroundColor: theme.palette.secondary.light,
        background: theme.palette.secondary.light,
    },
    secondarySelected: {
        background: theme.palette.secondary.light,
        borderColor: theme.palette.primary.main,
        borderLeft: 10,
        borderLeftStyle: 'solid'
    },
});

export class MenuDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: this.props.isDrawerOpen,
            isMenuOpen: this.props.isMenuOpen,
            support: false
        }
    }
    componentDidMount() {
        this.props.getUserProfile();
        this.props.getClientProfile(this.getUser().id_klijent);

        console.log("USER : ", this.getUser())
    }

    componentDidUpdate(prevProps) {
        const { isDrawerOpen, isMenuOpen } = this.props;
        if (prevProps.isDrawerOpen !== isDrawerOpen) {
            this.setState({
                isDrawerOpen: isDrawerOpen
            })
        }
        if (prevProps.isMenuOpen !== isMenuOpen) {
            this.setState({
                isMenuOpen: isMenuOpen
            })
        }
    }

    toggleDrawerOver() {
        this.props.toggleMenu(true);
    }
    toggleDrawerOut() {
        this.props.toggleMenu(false);
    }

    tokenCheck() {
        let token = localStorage.getItem('token');
        return token;
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    goTo(path) {
        this.props.history.push(path);
    }

    goToManuals() {
        window.open("https://manuals.agrolife.world/enterprise/", "_blank");
    }

    onSupportClick = () => {
        this.setState({ support: !this.state.support });
    }

    render() {
        let user = this.getUser();
        var avatarSrc = null;
        if (this.props.userProfile && this.props.userProfile.slika) avatarSrc = this.props.userProfile.signedURL;
        const { anchorEl } = this.state;
        const { classes } = this.props;
        return (
            <Paper isDrawerOpen={this.state.isDrawerOpen}
                isMenuOpen={this.state.isMenuOpen}
            // onMouseOver={() => this.toggleDrawerOver()}
            // onMouseOut={() => this.toggleDrawerOut()}
            >
                <Header isDrawerOpen={this.state.isDrawerOpen}
                    isMenuOpen={this.state.isMenuOpen} />
                <div className="listInsideMenu" >
                    <Divider style={{ width: '350px' }} />
                    <div>
                        <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }} expanded={false}>
                            <ExpansionPanelSummary onClick={() => this.goTo('/activities')} className={window.location.pathname.startsWith('/activities') || window.location.pathname.startsWith('/new_activity') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                <span className="icon-NoviRadniNalog menuIcon" />
                                <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Activities')}</Typography>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    </div>

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'maps';
                    }) ? <div>
                        <Divider style={{ width: '350px' }} />
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px', boxShadow: '0px 0px 0px 0px' }} />}>
                                    <span className="icon-TableLista menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Map')}</Typography>
                                </ExpansionPanelSummary>

                                {/* <ExpansionPanelDetails className={window.location.pathname === '/map' ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem  onClick={() => this.goTo('/map')}>
                                        <span className="icon-Map menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('View map')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails> */}
                                <ExpansionPanelDetails className={window.location.pathname === '/maps' ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/maps')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Map menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('View map')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {user.id_korisnik_grupa !== 11 && <ExpansionPanelDetails className={window.location.pathname.includes('/parcel') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/parcel')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Fields menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px', width: '100%' }}>{i18n.t('Parcels')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>}
                                {user.id_korisnik_grupa !== 11 && <ExpansionPanelDetails className={window.location.pathname.includes('/field') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/field')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-TableLista menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Public green areas')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>}
                                {user.id_region === 27 &&
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/partsofthefield') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/partsofthefield')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-TableLista menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Parts of the field')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                }
                                {user.id_korisnik_grupa !== 11 && <ExpansionPanelDetails className={window.location.pathname.includes('/archivelist') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/archivelist')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-TableLista menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px', whiteSpace: 'break-spaces' }}>{i18n.t('Archived public green areas')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>}
                                <ExpansionPanelDetails className={window.location.pathname.includes('/notes') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/notes')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-libreoffice menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Notes')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {user.id_klijent_grupa === 16 && user.id_korisnik_grupa === 10 && user.id_klijent !== 32413 &&
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/sensors')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-feed menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sensors')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                }

                                {user.id_klijent === 32413 && <ExpansionPanelDetails className={window.location.pathname.includes('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/sensors_new')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-feed menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sensors')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>}

                                {user.id_korisnik_grupa === 11 && user.id_klijent !== 32413 &&
                                <ExpansionPanelDetails className={window.location.pathname.includes('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/sensors')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-feed menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sensors')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                }
                                {/* {user.id_klijent === 29077 && user.id_region === 13 &&
                                    <ExpansionPanelDetails className={window.location.pathname === '/etfarm2' ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem  onClick={() => this.goTo('/etfarm2')}>
                                            <span className="icon-Map menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('etFarm')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                } */}
                                {/* {user.id_region === 26 &&
                                    <ExpansionPanelDetails className={window.location.pathname === '/etfarm' ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem  onClick={() => this.goTo('/etfarm')}>
                                            <span className="icon-Map menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('etFarm')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                } */}

                            </ExpansionPanel>
                        </div>
                    </div> : null}
                    {user.id_korisnik_grupa === 10 &&
                        <div>
                            <Divider style={{ width: '288px' }} />
                            <div>
                                <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px', boxShadow: '0px 0px 0px 0px' }} />}>
                                        <span className="icon-Resources menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Resources')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/material') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/material')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Material menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Materials')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/crop') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/crop')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Biljka menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Crops')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    {user.id_region === 27 &&
                                        <ExpansionPanelDetails className={window.location.pathname.includes('/varieties') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/varieties')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Biljka menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Crop varieties')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    }
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/machines') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/machines')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Machines menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Machines')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/implement') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/implement')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Implements menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Implements')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/working_operation') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/working_operation')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Working-Operation menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Working operations')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    {/* <ExpansionPanelDetails className={window.location.pathname.includes('/workers') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/workers')}>
                                            <span className="icon-users menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Workers')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/workplaces') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/workplaces')}>
                                            <span className="icon-users menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Workplaces')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                                </ExpansionPanel>
                            </div>
                        </div>
                    }

                    <div>
                        <Divider style={{ width: '350px' }} />
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px', boxShadow: '0px 0px 0px 0px' }} />}>
                                    <span className="icon-users menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Workers')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/work_activities') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/work_activities')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-list2 menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Worker activities')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/workers') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/workers')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-user menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Workers')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {user.id_korisnik_grupa === 10 &&
                                    <div>
                                        <ExpansionPanelDetails className={window.location.pathname.includes('/workplaces') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/workplaces')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-users menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Workplaces')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                        <ExpansionPanelDetails className={window.location.pathname.includes('/work_units') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/work_units')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Zubcanik menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Worker units')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                        {/* <ExpansionPanelDetails className={window.location.pathname.includes('/work_activity_list') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/work_activity_list')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Beleske menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Worker activity list')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}

                                    </div>
                                }
                            </ExpansionPanel>
                        </div>
                        
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px', boxShadow: '0px 0px 0px 0px' }} />}>
                                    <AccountBalanceWalletIcon
                                        style={{ color: 'white', marginRight: '30px' }}
                                    />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Extra expenses')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/extra_expenses') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/extra_expenses')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <ReceiptIcon
                                            style={{ color: 'white', marginRight: '30px' }}
                                        />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Expenses')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/administration_of_extra_expenses') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/administration_of_extra_expenses')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <MoneyIcon
                                            style={{ color: 'white', marginRight: '30px' }}
                                        />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Expense types')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                        </div>

                    </div>

                    {(user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv.includes('reports');
                    })) && user.id_korisnik_grupa !== 11 ? <div>
                        <Divider style={{ width: '350px' }} />
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-newspaper menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Reports')}</Typography>
                                </ExpansionPanelSummary>
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/analytics') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/analytics')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-pie-chart menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Revenue/Cost')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails> */}
                                <ExpansionPanelDetails className={window.location.pathname.includes('/material_cost') || window.location.pathname.startsWith('/material_table') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/material_cost')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Material menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Consumption - Cost')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/revenue_cost') || window.location.pathname.startsWith('/revenue_table') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/revenue_cost')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-coin-euro menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Yield - Revenue')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails> */}

                                <ExpansionPanelDetails className={window.location.pathname.includes('/workers_report') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/workers_report')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-user menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Worker Cost')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/agrodron_report') || window.location.pathname.startsWith('/agrodron_report') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/agrodron_report')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        {/* <span className="icon-camera menuIcon" /> */}
                                        <div style={{ marginRight: '10px', paddingRight: '15px', display: 'flex' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="_x32_" width="25" height="25" viewBox="0 0 512 512" xmlSpace="preserve">
                                                <style type="text/css">
                                                    {`.st0 {fill: #FFFFFF;}`}
                                                </style>
                                                <g>
                                                    <path className="st0" d="M425.797,130.088c-12.509,0-22.646,10.136-22.646,22.629v15.163l-62.255,12.053c-22.642,0-50.941-14.16-84.892-14.16c-33.965,0-62.259,14.16-84.901,14.16l-62.258-12.053v-15.163c0-12.493-10.133-22.629-22.638-22.629S63.57,140.224,63.57,152.718v70.757c0,12.492,10.132,22.63,22.637,22.63s22.638-10.137,22.638-22.63v-13.338c18.087,3.782,43.018,8.959,59.43,12.244c25.968,5.193,35.346,41.26,76.046,47.191v37.477c-6.533,0.547-11.68,5.964-11.68,12.634c0,0.099,0.029,0.191,0.029,0.29c-23.036,9.257-39.315,31.762-39.315,58.107c0,34.598,28.046,62.645,62.645,62.645c34.599,0,62.645-28.046,62.645-62.645c0-26.346-16.279-48.85-39.314-58.107c0-0.1,0.029-0.191,0.029-0.29c0-6.67-5.148-12.086-11.68-12.634v-37.477c40.696-5.932,50.082-41.998,76.045-47.191c16.407-3.285,41.343-8.461,59.426-12.244v13.338c0,12.492,10.137,22.63,22.646,22.63c12.501,0,22.629-10.137,22.629-22.63v-70.757C448.426,140.224,438.298,130.088,425.797,130.088z M297.409,378.079c0,22.87-18.539,41.409-41.409,41.409c-22.87,0-41.409-18.54-41.409-41.409c0-22.87,18.54-41.409,41.409-41.409C278.87,336.67,297.409,355.209,297.409,378.079z" />
                                                    <rect y="90.993" className="st0" width="172.414" height="29.556" />
                                                    <rect x="339.577" y="90.993" className="st0" width="172.423" height="29.556" />
                                                    <path className="st0" d="M86.207,71.276c-7.142,0-12.928,5.79-12.928,12.94h25.86C99.139,77.066,93.344,71.276,86.207,71.276z" />
                                                    <path className="st0" d="M425.797,71.276c-7.15,0-12.94,5.79-12.94,12.94h25.864C438.72,77.066,432.931,71.276,425.797,71.276z" />
                                                    <path className="st0" d="M267.555,354.28c-3.509-1.7-7.395-2.745-11.555-2.745c-14.662,0-26.544,11.887-26.544,26.544s11.883,26.544,26.544,26.544c14.661,0,26.544-11.887,26.544-26.544c0-3.584-0.73-7.001-2.02-10.12c-1.506,4.031-5.317,6.934-9.875,6.934c-5.864,0-10.618-4.753-10.618-10.618C260.032,359.515,263.229,355.624,267.555,354.28z" />
                                                </g>
                                            </svg>
                                        </div>
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Drone recording')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {/* <ExpansionPanelDetails className={window.location.pathname.includes('/workers_dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem  onClick={() => this.goTo('/workers_dashboard')}>
                                        <span className="icon-users menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Workers - Cost')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails> */}
                                {/* {user.id_korisnik_grupa === 10 &&
                                    <ExpansionPanelDetails className={window.location.pathname.includes('/enterprise') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                        <MenuItem onClick={() => this.goTo('/enterprise')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Beleske menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('List of activities')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                }
                                <ExpansionPanelDetails className={window.location.pathname.includes('/crop_rotation') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/crop_rotation')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Common menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Crop rotation')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails> */}
                            </ExpansionPanel>
                        </div>
                    </div> : null}

                    <Divider style={{ width: '350px' }} />

                    {user.id_korisnik_grupa === 10 &&
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-Government menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Administration')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/sectors') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/sectors')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Zubcanik menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sectors')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/users') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/users')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-users menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Users')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <Divider style={{ width: '350px' }} />
                            {/* <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")}>
                                <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-price-tag menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Traffic')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/storage') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem  onClick={() => this.goTo('/storage')}>
                                        <span className="icon-Kutija menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Storage')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails className={window.location.pathname.includes('/loads') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem  onClick={() => this.goTo('/loads')}>
                                        <span className="icon-Kombajn menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Loads')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <Divider style={{ width: '350px' }} />
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/market_prices')} className={window.location.pathname.startsWith('/market_prices') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-price-tags menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Market prices')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                            <Divider style={{ width: '350px' }} /> */}
                        </div>
                    }
                    <div>
                        <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px', borderBottom: '1px solid rgba(4, 104, 69, 0.9)' }}>
                            <ExpansionPanelSummary className={classnames(classes.secondaryMain, "expansion-panel-summary")} expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                <span className="icon-question menuIcon" />
                                <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Help')}</Typography>
                            </ExpansionPanelSummary>
                            {/* <ExpansionPanelDetails className={classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                <MenuItem onClick={() => this.goToManuals()} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                    <span className="icon-info menuIcon" />
                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Manuals')}</Typography>} />
                                </MenuItem>
                            </ExpansionPanelDetails> */}
                            <ExpansionPanelDetails className={this.state.support ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                <MenuItem onClick={() => this.onSupportClick()} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                    <span className=" icon-question menuIcon" />
                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Support')}</Typography>} />
                                </MenuItem>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div >

                {this.state.support && <Support open={this.state.support}
                    handleClose={this.onSupportClick} />}
            </Paper >
        );
    }
}

function mapStateToProps(state) {
    return {
        isDrawerOpen: state.appReducer.isDrawerOpen,
        isMenuOpen: state.appReducer.isMenuOpen,
        user: state.appReducer.user,
        userProfile: state.appReducer.userProfile
    };
}
function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        toggleMenu: open => dispatch(toggleMenu(open)),
        logout: open => dispatch(logout(open)),
        getUserProfile: () => dispatch(getUserProfile()),
        getClientProfile: (id) => dispatch(getClientProfile(id)),
    };
}
export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuDrawer)));