import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, IconButton, DialogTitle } from '@material-ui/core';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import AddLoad from './AddLoad';
import { withStyles } from '@material-ui/core/styles';
import { getLoads, deleteLoad, setInitialState, setRowInReducer } from '../../actions/traffic/TrafficActions';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../utils/Delete';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
});
export class ViewLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            edit: false
        };
    }


    componentDidUpdate(prevProps) {
        const { deleted, loadsPostingSuccess, deleteFailed, getLoads, setInitialState } = this.props;
        if (!prevProps.deleted && deleted) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            getLoads(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            setInitialState('loadsObj');
            setInitialState('deleted');
        }
        if (!prevProps.loadsPostingSuccess && loadsPostingSuccess) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            getLoads(wrapped.state);
            setInitialState('loadsPostingSuccess');
        }
        if (!prevProps.deleteFailed && deleteFailed) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('deleteFailed');
        }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        let { edit, add, selection } = this.state;
        let { setInitialState } = this.props;
        if (edit == true && add == false) {
            this.setState({ edit: false })
            setInitialState('loadsObj');
        } else if (selection.length > 0 && add == false && edit == false) {
            setInitialState('loadsObj');
        }
        this.setState({ add: !add }, () => {
            if (add == true && edit == false) {
                this.setState({
                    selection: [],
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        const { selection: stateSelection } = this.state;
        const { setInitialState, setRowInReducer, loads } = this.props;
        let selection = [...stateSelection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            setInitialState('loadsObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let rowForReducer = loads.filter((storage) => {
                    return storage.id == selection;
                })
                setRowInReducer(rowForReducer[0], 'loadsObj');
            }
        });
    };

    toggleAll = () => {
        const { selectAll: selectAllState } = this.state;
        const selectAll = selectAllState ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });


    };

    isSelected = key => {
        const { selection } = this.state;
        return selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteLoad(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {
        const columns = [
            {
                Header: i18n.t('ID'),
                accessor: 'id'
            },
            {
                Header: i18n.t('Commodity'),
                accessor: 'materijal_naziv'
            },
            {
                Header: i18n.t('Source'),
                accessor: 'silos_ulaz'
            },
            {
                Header: i18n.t('Public green area'),
                accessor: 'tabla_naziv'
            },
            {
                Header: i18n.t('Destination'),
                accessor: 'silos_izlaz'
            },
            {
                Header: i18n.t('Ticket#'),
                accessor: 'tiket'
            },
            {
                Header: i18n.t('Moisture content'),
                accessor: 'iznos_vlage'
            },
            {
                Header: i18n.t('Date'),
                accessor: 'datum'
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis'
            },
        ];
        const { theme, loads, loadsPages, loadsFetching, getLoads } = this.props;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, selection, showFilters, add, edit } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#cfc980" : "inherit"
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Button
                        onClick={this.handleClick}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Button>
                    <Button
                        onClick={() => this.onSearchClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Button>
                    {selection.length > 0 &&
                        <Button
                            onClick={() => this.onDeleteClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Button>
                    }
                    {selection.length === 1 &&
                        <Button
                            onClick={() => this.onEditClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Izmeni1 headerIcon" />
                        </Button>
                    }

                </div>
                <CheckboxTable data={loads}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={showFilters}
                    pages={loadsPages}
                    loading={loadsFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => getLoads(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                />
                {add && <AddLoad add={add} edit={edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        loads: state.trafficReducer.loads,
        loadsObj: state.trafficReducer.loadsObj,
        loadsFetching: state.trafficReducer.loadsFetching,
        loadsPages: state.trafficReducer.loadsPages,
        deleted: state.trafficReducer.deleted,
        deleteFailed: state.trafficReducer.deleteFailed,
        loadsPostingSuccess: state.trafficReducer.loadsPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLoads: (state, instance) => dispatch(getLoads(state, instance)),
        deleteLoad: (selection) => dispatch(deleteLoad(selection)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLoad))