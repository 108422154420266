import React from 'react';
import { Button, IconButton, TextField, Card, CardHeader, CardContent, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip, Menu, MenuItem, InputLabel } from '@material-ui/core';
import styles from './newactivity.module.css';
import status from './status.module.css';
import i18n from '../../../i18n/i18n';
import { wentRight, wentWrong } from '../../../utils/ToastConfig';
import axios from '../../../utils/AxiosWrapper';
import WorkingOperation from './../working_operation/WorkingOperation';
import DateAndTime from '../date_and_time/DateAndTime';
import Fields from '../fields/Fields';
import Materials from '../materials/Materials';
import Products from '../products/Products';
import Machines from '../machines/Machines';
import Implements from '../implements/Implements';
import _ from 'lodash';
import Rows from '../rows/Rows';

const STATUS_FINISHED = 1

export default class NewActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activityObj: {},
            workingOperationOpen: false,
            workingOperationString: '',
            dateAndTimeOpen: false,
            fieldsOpen: false,
            materialsOpen: false,
            productsOpen: false,
            machinesOpen: false,
            implementsOpen: false,
            commentOpen: false,
            comment: '',
            statusOpen: true,
            tooltipOpen: false,
            tooltipProgress: false,
            tooltipFinished: false,
            tooltipLocked: false,
            tooltipPlanned: false,
            copy: false,
            currency: {},
            totalMaterials: 0,
            totalYield: 0,
            editMaterial: null,
            hasYieldIndicator: false,
            rowsOpen: false,
            edit_table_redovi: [],
            edit_tabla_id: null
        };
    };

    componentDidMount = () => {
        // axios.get('api/valuta/currency')
        //     .then(res => {
        //         console.log(res.data.data)
        //         this.setState({ currency: res.data.data[0] })
        //     })
        //     .catch(() => wentWrong('Something went wrong'))
        let user = this.getUser();
        if (user.id_region === 2) this.setState({ currency: { naziv: 'RSD' } })
        else if (user.id_region === 13) this.setState({ currency: { naziv: 'NGN' } })
        else if (user.id_region === 20) this.setState({ currency: { naziv: 'BAM' } })
        else if (user.id_region === 21) this.setState({ currency: { naziv: 'BAM' } })
        else if (user.id_region === 22) this.setState({ currency: { naziv: 'EUR' } })
        else if (user.id_region === 23) this.setState({ currency: { naziv: 'EUR' } })
        else if (user.id_region === 24) this.setState({ currency: { naziv: 'USD' } })
        else if (user.id_region === 25) this.setState({ currency: { naziv: 'ILS' } })
        else if (user.id_region === 26) this.setState({ currency: { naziv: 'CAD' } })
        else if (user.id_region === 27) this.setState({ currency: { naziv: 'ILS' } })
        else if (user.id_region === 29) this.setState({ currency: { naziv: 'EUR' } })
        else if (user.id_region === 30) this.setState({ currency: { naziv: 'EUR' } })
        else if (user.id_region === 31) this.setState({ currency: { naziv: 'RSD' } })

        this.handleStatus(0);
        if (this.props.location) {
            if (this.props.location.state) {
                if (this.props.location.state.propObj) {
                    if (this.props.location.state.propObj.id === undefined) this.setState({ copy: true })
                    this.setState({
                        activityObj:
                        {
                            ...this.props.location.state.propObj,
                            redovi: this.props.location.state.propObj.redovi
                        } }, () => {
                        this.setState({ workingOperationString: this.props.location.state.propObj.radna_operacija }, () => {
                            if (this.props.location.state.propObj.komentar !== null) {
                                this.setState({ comment: this.props.location.state.propObj.komentar })
                            }
                            else this.deleteComment();
                        })

                        let svi_redovi = []
                        this.state.activityObj.table.map(item => {
                            const edit_table_redovi = this.makeEditTableRedovi(item)
                            const redovi = this.makeUpdateActivityObjectRows(edit_table_redovi).redovi
                            svi_redovi = redovi
                        })

                        this.setState(prev => {
                            return { ...prev, activityObj: { ...prev.activityObj, redovi: svi_redovi } }
                        })

                    });
                    if (this.props.location.state.propObj.indikator_prinosa === true) this.setState({ hasYieldIndicator: true })
                }

            }
        }       
    };

    goTo = (path) => this.props.history.push(path);

    handleWorkingOperation = () => this.setState({ workingOperationOpen: !this.state.workingOperationOpen });
    handleDateAndTime = () => this.setState({ dateAndTimeOpen: !this.state.dateAndTimeOpen });
    handleFields = () => this.setState({ fieldsOpen: !this.state.fieldsOpen });
    handleMaterials = () => this.setState({ materialsOpen: !this.state.materialsOpen });
    handleMaterialsClose = () => this.setState({ materialsOpen: !this.state.materialsOpen, editMaterial: null});
    handleProducts = () => this.setState({ productsOpen: !this.state.productsOpen });
    handleProductsClose = () => this.setState({ productsOpen: !this.state.productsOpen, editMaterial: null});
    handleMachines = () => this.setState({ machinesOpen: !this.state.machinesOpen });
    handleImplements = () => this.setState({ implementsOpen: !this.state.implementsOpen });
    handleStatusOpen = () => this.setState({ statusOpen: !this.state.statusOpen });
    toggleRows = () => this.setState({ rowsOpen: !this.state.rowsOpen});

    handleWorkingOperationObj = (workingOperation, hasYieldIndicator, workingOperationDescription) => {
        let obj = this.state.activityObj;
        obj.id_radna_operacija = workingOperation.value;
        obj.opis_radna_operacija = workingOperationDescription;
        // obj.materijali = [];
        let filtered = [];
        if (hasYieldIndicator === false && obj.materijali) {
            filtered = obj.materijali.filter(item => item.indikator_prinosa !== 1);
            obj.materijali = filtered;
        }
        this.setState({ activityObj: obj, hasYieldIndicator: hasYieldIndicator }, () => {
            this.setState({ workingOperationString: workingOperation.label }, () => {
                this.handleWorkingOperation();
            });
        });
    };

    handleDateAndTimeObj = (startDate, endDate, startTime, endTime) => {
        let obj = this.state.activityObj;
        obj.datum_pocetka = startDate;
        obj.datum_zavrsetka = endDate;
        obj.vreme_pocetka = startTime;
        obj.vreme_zavrsetka = endTime;
        this.setState({ activityObj: obj }, () => this.handleDateAndTime());
    };

    handleFieldsObj = (fields) => {
        let obj = this.state.activityObj;
        obj.table = fields;

        let svi_redovi = []
        fields.map(item => {
            const edit_table_redovi = this.makeEditTableRedovi(item)
            const redovi = this.makeUpdateActivityObjectRows(edit_table_redovi).redovi

            if (redovi !== undefined) {
                svi_redovi = redovi
            }
        })

        this.setState({ activityObj: {...obj, redovi: svi_redovi } }, () => this.handleFields());
    };

    makeUpdateActivityObjectRows = rows => {
        let obj = this.state.activityObj

        if (obj.redovi && rows.length > 0) {
            obj.redovi = obj.redovi.filter(red => red.red_id_tabla !== rows[0].red_id_tabla)
        }

        rows.forEach(noviRed => {
            if (obj.redovi) {
                obj.redovi.push(noviRed)
            }
            else {
                if (obj.redovi === undefined) {
                    obj.redovi = []
                }
                obj.redovi.push(noviRed)
            }
        })

        return obj
    }

    handleRowsObj = (id_table, rows) => {
        const obj = this.makeUpdateActivityObjectRows(rows)

        const edit_table_redovi_update_index = this.state.edit_table_redovi.findIndex(et => et.id_table === id_table)
        let editTablaUpdateObj = this.state.edit_table_redovi[edit_table_redovi_update_index]
        editTablaUpdateObj.redovi = rows


        const newRedovi = [
            ...this.state.edit_table_redovi.slice(0, edit_table_redovi_update_index),
            editTablaUpdateObj,
            ...this.state.edit_table_redovi.slice(edit_table_redovi_update_index + 1)
        ]

        this.setState({ activityObj: obj, edit_table_redovi: newRedovi }, () => this.toggleRows())
    }

    handleMaterialsObj = (material, edit, editMaterial) => {
        let obj = this.state.activityObj;
        if (this.state.activityObj.materijali === undefined) {
            obj.materijali = [];
            obj.materijali.push(material);
        }
        else {
            if (edit === false && editMaterial === null) obj.materijali.push(material);
            else obj.materijali[editMaterial] = material;
        }
        const mergedMaterials = [];
        obj.materijali.forEach((mat) => {
            const existingMaterial = mergedMaterials.find((m) => {
                return m.id_materijal === mat.id_materijal && parseFloat(m.cena_po_jm) === parseFloat(mat.cena_po_jm);
            });
    
            if (existingMaterial) {
                existingMaterial.utroseno = parseFloat(existingMaterial.utroseno) + parseFloat(mat.utroseno);
            } else {
                mergedMaterials.push({ ...mat });
            }
        });

        obj.materijali = mergedMaterials;
        this.setState({ activityObj: obj, editMaterial: null}, () => this.handleMaterials());
    };

    handleProductsObj = (product, edit, editMaterial) => {
        let obj = this.state.activityObj;
        if (this.state.activityObj.materijali === undefined) {
            obj.materijali = [];
            obj.materijali.push(product);
        }
        else {
            if (edit === false && editMaterial === null) obj.materijali.push(product);
            else obj.materijali[editMaterial] = product;
        }
        this.setState({ activityObj: obj, editMaterial: null}, () => this.handleProducts());
    };

    handleMachinesObj = (machine) => {
        let obj = this.state.activityObj;
        obj.pogonskeMasine = machine;
        this.setState({ activityObj: obj }, () => this.handleMachines());
    };

    handleImplementsObj = (machine) => {
        let obj = this.state.activityObj;
        obj.prikljucneMasine = machine;
        this.setState({ activityObj: obj }, () => this.handleImplements());
    };

    handleComment = (comment) => {
        let obj = this.state.activityObj;
        obj.komentar = comment;
        this.setState({ activityObj: obj });
    };

    handleStatus = (id) => {
        let obj = this.state.activityObj;
        obj.id_status = id;
        this.setState({ activityObj: obj }, () => {
            this.setState({
                statusOpen: !this.state.statusOpen
            })
        });
    };

    handleInitialStatus = (id) => {
        let obj = this.state.activityObj;
        obj.id_status = id;
        this.setState({ activityObj: obj });
    };


    deleteWorkingOperation = () => {
        let obj = this.state.activityObj;
        delete obj.id_radna_operacija;
        delete obj.opis_radna_operacija;
        // obj.materijali = [];

        let filtered = [];
        if (obj.materijali) {
            filtered = obj.materijali.filter(item => item.indikator_prinosa !== 1);
            obj.materijali = filtered;
        }

        this.setState({ activityObj: obj, hasYieldIndicator: false }, () => {
            this.setState({ workingOperationString: '' });
        });
    };

    deleteDateAndTime = () => {
        let obj = this.state.activityObj;
        delete obj.datum_pocetka;
        delete obj.datum_zavrsetka;
        delete obj.vreme_pocetka;
        delete obj.vreme_zavrsetka;
        this.setState({ activityObj: obj });
    };

    deleteFields = (row) => {
        let obj = this.state.activityObj;
        let fields = obj.table;
        delete obj.table;
        fields.splice(row, 1);
        obj.table = fields;

        let svi_redovi = []
        obj.table.map(item => {
            const tabla_redovi = obj.redovi.filter(red => red.red_id_tabla === item.id_tabla)
            svi_redovi = svi_redovi.concat(tabla_redovi)
        })

        if (obj.table.length === 0) obj.table = undefined;       

        this.setState({ activityObj: {...obj, redovi: svi_redovi } });
    };

    deleteMaterials = (row) => {
        let obj = this.state.activityObj;
        let materials = obj.materijali;
        delete obj.materijali;
        materials.splice(row, 1);
        obj.materijali = materials;
        if (obj.materijali.length === 0) obj.materijali = undefined;
        this.setState({ activityObj: obj });
    };

    deleteProducts = (row) => {
        let obj = this.state.activityObj;
        let products = obj.materijali;
        delete obj.materijali;
        products.splice(row, 1);
        obj.materijali = products;
        if (obj.materijali.length === 0) obj.materijali = undefined;
        this.setState({ activityObj: obj });
    };

    deleteMachines = (row) => {
        let obj = this.state.activityObj;
        let machines = obj.pogonskeMasine;
        delete obj.pogonskeMasine;
        machines.splice(row, 1);
        obj.pogonskeMasine = machines;
        if (obj.pogonskeMasine.length === 0) obj.pogonskeMasine = undefined;
        this.setState({ activityObj: obj });
    };

    deleteImplements = (row) => {
        let obj = this.state.activityObj;
        let machines = obj.prikljucneMasine;
        delete obj.prikljucneMasine;
        machines.splice(row, 1);
        obj.prikljucneMasine = machines;
        if (obj.prikljucneMasine.length === 0) obj.prikljucneMasine = undefined;
        this.setState({ activityObj: obj });
    };

    deleteComment = () => {
        let obj = this.state.activityObj;
        delete obj.komentar;
        this.setState({ activityObj: obj });
    };

    handleAdd = () => {
        const { activityObj } = this.state;
        axios.get('api/season/activity_season')
            .then(res => {
                let activity = {
                    activityObj: activityObj,
                    season: res.data.data[0].id_default_sezona
                }
                // this.props.addActivity(activity);
                axios.post(`api/activity/create`, activity)
                    .then(() => {
                        wentRight(i18n.t('Successfully added activity'))
                        this.goTo('/')
                    })
                    .catch(() => wentWrong(i18n.t('Something went wrong')))

                if (this.props.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 16 } })
                else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 13 } })

            })
            .catch(err => console.log('Error: ', err))
    };

    isOdd = (num) => { return num % 2; };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    };

    makeEditTableRedovi(item) {
        const pickedRows = this.state.activityObj.redovi

        // if has no picked rows, get data from table related rows table
        let get_redovi_from_tabla_redovi = false

        if (this.props.location
            && this.props.location.state
            && this.props.location.state.is_copy !== undefined
            && this.props.location.state.is_copy === true) {

            if (this.state.activityObj.id_status === STATUS_FINISHED) {
                get_redovi_from_tabla_redovi = true
            }
        }
        else {
            get_redovi_from_tabla_redovi = !(pickedRows !== undefined && pickedRows.some(red => red.red_id_tabla === item.id_tabla))
        }

        let edit_table_redovi = get_redovi_from_tabla_redovi
            ? item.raspored_redova.map(red => {
                let redRadniNalogTabla = {
                    red_id_kultura_sorta: red.id_kultura_sorta,
                    red_broj_reda: red.broj_reda,
                    red_broj_sadnica: red.broj_sadnica,
                    red_id_tabla: item.id_tabla,
                    red_odabran: true,
                    kultura_sorta: red.kultura_sorta
                }

                return redRadniNalogTabla
            }) : pickedRows.some(red => red.red_id_tabla === item.id_tabla) ? pickedRows.filter(red => red.red_id_tabla === item.id_tabla).map(red => {
                let redCopy = JSON.parse(JSON.stringify(red))
                delete redCopy.id
                delete redCopy.id_radni_nalog

                return redCopy
            }) : []

        return edit_table_redovi
    }

    openPickRowsModal(item) {
        this.setState(prev => {
            let edit_table_redovi = this.makeEditTableRedovi(item)

            const edit_table_redovi_obj = { id_table: item.id_tabla, redovi: edit_table_redovi }
            return { ...prev, edit_tabla_id: item.id_tabla, edit_table_redovi: [...prev.edit_table_redovi, edit_table_redovi_obj] }
        })

        this.toggleRows()
    }

    getRowsPickedPreviewNumbers (item) {
        let redovi = this.makeEditTableRedovi(item)

        console.log("redovi", redovi);
        const edit_tabla_redovi = this.state.edit_table_redovi.find(tabla_redovi => tabla_redovi.id_table === item.id_tabla)

        if (edit_tabla_redovi) {
            redovi = edit_tabla_redovi.redovi
        }

        let firstPart = redovi !== undefined ? redovi.filter(red =>
            red.red_id_tabla === item.id_tabla && red.red_odabran === true).length : ''
        let secondPart = redovi !== undefined ? redovi.filter(red =>
            red.red_id_tabla === item.id_tabla).length : ''
        
        if(secondPart === 0){
            return ''
        }
        return firstPart + '/' + secondPart
    }

    calculateRowsPickedPreview = (item) => {
        let redovi = this.makeEditTableRedovi(item)
        const edit_tabla_redovi = this.state.edit_table_redovi.find(tabla_redovi => tabla_redovi.id_table === item.id_tabla)

        if (edit_tabla_redovi) {
            redovi = edit_tabla_redovi.redovi
        }
        if (!redovi) return '';

        let firstPart = redovi
            .filter(red => red.red_id_tabla === item.id_tabla && red.red_odabran === true)
            .reduce((sum, red) => sum + red.red_broj_sadnica, 0);

        let secondPart = redovi
            .filter(red => red.red_id_tabla === item.id_tabla)
            .reduce((sum, red) => sum + red.red_broj_sadnica, 0);
        
        if(secondPart === 0){
            return ''
        }

        return `${firstPart} / ${secondPart}`;
    }

    

    render() {
        let materialList = [], productList = [];

    if (this.state.activityObj.materijali) {
        const groupedMaterials = {}; 

        this.state.activityObj.materijali.forEach((item, i) => {
            let obj = { ...item };
            obj.matId = i;

            if (item.indikator_prinosa === null) {
                const key = `${item.matId}-${item.cena_po_jm}`;
                if (!groupedMaterials[key]) {
                    groupedMaterials[key] = { ...obj, utroseno: Number(obj.utroseno) };
                } else {
                    groupedMaterials[key].utroseno += Number(obj.utroseno);
                }
            } else if (item.indikator_prinosa === 1) {
                productList.push(obj);
            }
        });

        materialList = Object.values(groupedMaterials);
    };

        

        let statusColor = '#58B4E5', statusText = i18n.t('Choose status') + '*', statusIcon = './status/otvoren.svg';
        if (this.state.activityObj) {
            if (this.state.activityObj.id_status) {
                switch (this.state.activityObj.id_status) {
                    case 1:
                        statusColor = '#61a114';
                        statusText = `${i18n.t('Status')}: ${i18n.t('Finished')}`;
                        statusIcon = './status/zavrsen.svg';
                        break;
                    case 2:
                        statusColor = '#c3d600';
                        statusText = `${i18n.t('Status')}: ${i18n.t('In progress')}`;
                        statusIcon = './status/utoku.svg';
                        break;
                    default:
                        statusColor = '#49c954';
                        statusText = i18n.t('Choose status');
                        statusIcon = './status/zavrsen.svg';
                        break;
                };
            };
        };

        let saveDisabled = true;
        if (this.state.activityObj.id_radna_operacija) {
            if (this.state.activityObj.datum_pocetka) {
                if (this.state.activityObj.table && this.state.activityObj.table.length > 0) {
                    if (this.state.activityObj.id_status !== 0) {
                        saveDisabled = false;
                    };
                };
            };
        };

        let editText = false;
        if (this.props.location) {
            if (this.props.location.state) {
                if (this.props.location.state.propObj) {
                    if (this.props.location.state.propObj.id !== undefined) {
                        if (this.props.location.state.edit === true) {
                            editText = true;
                        };
                    };
                };
            };
        };

        let totalMaterials = 0;
        let totalYield = 0;
        if (this.state.activityObj) {
            if (this.state.activityObj.materijali) {
                if (this.state.activityObj.materijali.length > 0) {
                    this.state.activityObj.materijali.forEach((item, i) => {
                        if (item.utroseno) totalMaterials += (Number(item.utroseno) * Number(item.cena_po_jm));
                        if (item.prinos) totalYield += (Number(item.prinos) * Number(item.cena_po_jm));
                    });
                };
            };
        };

        return (
            <div className={styles.page}>
                <div className={styles.back_container} onClick={() => this.goTo('/activities')}>
                    <img role='button' alt="none" src='./back.svg' height='24px' onClick={() => this.goTo('/activities')} />
                    <div className={styles.back}>
                        {i18n.t('Back to activity list')}
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.title}>
                        {editText ? i18n.t('Edit activity') : i18n.t('Add activity')}
                    </div>

                    <div className={styles.sections_container}>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./production.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Working operation')}*</div>
                                    {this.state.activityObj.id_radna_operacija === undefined && <div className={styles.add_button} onClick={() => this.handleWorkingOperation()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleWorkingOperation()} />
                                    </div>}
                                </div>
                                {this.state.activityObj.id_radna_operacija && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleWorkingOperation()} />}
                            </div>
                            {this.state.activityObj.id_radna_operacija === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have not selected a working operation')}</div>
                                </div>
                            }
                            {this.state.activityObj.id_radna_operacija &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            <div style={{ minWidth: 'fit-content' }}>
                                                <div className={styles.working_operation_color}>
                                                    {i18n.t('Working operation')}:
                                                </div>
                                                <div className={styles.working_operation}>
                                                    {this.state.workingOperationString}
                                                </div>
                                            </div>
                                            {this.state.activityObj.opis_radna_operacija &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Activities description')}:
                                                    </div>
                                                    <div className={styles.working_operation} style={{ textAlign: 'justify' }}>
                                                        {this.state.activityObj.opis_radna_operacija}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} onClick={() => this.deleteWorkingOperation()} />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./date.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Date and time')}*</div>

                                    {this.state.activityObj.vreme_pocetka === undefined &&
                                        this.state.activityObj.vreme_zavrsetka === undefined &&
                                        this.state.activityObj.datum_pocetka === undefined &&
                                        this.state.activityObj.datum_zavrsetka === undefined &&
                                        <div className={styles.add_button} onClick={() => this.handleDateAndTime()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleDateAndTime()} />
                                        </div>
                                    }
                                </div>
                            </div>
                            {this.state.activityObj.vreme_pocetka === undefined &&
                                this.state.activityObj.vreme_zavrsetka === undefined &&
                                this.state.activityObj.datum_pocetka === undefined &&
                                this.state.activityObj.datum_zavrsetka === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected dates and times')}</div>
                                </div>
                            }
                            {this.state.activityObj.vreme_pocetka !== undefined &&
                                this.state.activityObj.vreme_zavrsetka !== undefined &&
                                this.state.activityObj.datum_pocetka !== undefined &&
                                this.state.activityObj.datum_zavrsetka !== undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            {this.state.activityObj.datum_pocetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Start date')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.datum_pocetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.datum_zavrsetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('End date')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.datum_zavrsetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.vreme_pocetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('Start time')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.vreme_pocetka}
                                                    </div>
                                                </div>
                                            }
                                            {this.state.activityObj.vreme_zavrsetka &&
                                                <div>
                                                    <div className={styles.working_operation_color}>
                                                        {i18n.t('End time')}:
                                                    </div>
                                                    <div className={styles.working_operation}>
                                                        {this.state.activityObj.vreme_zavrsetka}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} onClick={() => this.deleteDateAndTime()} />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./field_3.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Public green areas')}*</div>
                                    {(this.state.activityObj.table === undefined || this.state.activityObj.table.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleFields()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleFields()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.table && this.state.activityObj.table.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleFields()} />}
                            </div>
                            {(this.state.activityObj.table === undefined || this.state.activityObj.table.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected public green areas')}</div>
                                </div>
                            }
                            {this.state.activityObj.table && this.state.activityObj.table.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Title')}</th>
                                                <th className={styles.table_header}>{i18n.t('Land area')}</th>
                                                {/* <th className={styles.table_header}>{i18n.t('Number of rows with picked')}</th>
                                                <th className={styles.table_header}>{i18n.t('Number of seedlings with picked')}</th> */}
                                                {/* <th className={styles.table_header}></th> */}
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.table.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.povrsina)).toFixed(2)} ha</td>                                                   
                                                    <td className={styles.table_row_text}>{this.getRowsPickedPreviewNumbers(item)} </td>
                                                    <td className={styles.table_row_text} style={{ minWidth: '250px' }}>
                                                            {this.calculateRowsPickedPreview(item)}
                                                            </td>
                                                    <td className={styles.table_row_text}>
                                                        {item.raspored_redova[0] !== undefined &&
                                                        <button
                                                            onClick={() => this.openPickRowsModal(item)}
                                                            className={styles.pick_rows_button}
                                                        >{i18n.t('Pick rows')}</button>
                                                        }
                                                    </td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteFields(i)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                    {this.state.activityObj.table.length > 0 &&
                                        <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
                                            {i18n.t('Total land area')}: {(this.state.activityObj.table.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./material.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Materials')}</div>
                                    <div className={styles.add_button} onClick={() => this.handleMaterials()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleMaterials()} />
                                    </div>
                                </div>
                                {/* <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleMaterials()} /> */}
                            </div>
                            {materialList.length === 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no added materials')}</div>
                                </div>
                            }
                            {materialList.length > 0 && Object.keys(this.state.currency).length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Material')}</th>
                                                <th className={styles.table_header}>{i18n.t('Amount spent')}</th>
                                                <th className={styles.table_header}>{i18n.t('Price per unit')}</th>
                                                <th className={styles.table_header}>{i18n.t('Total')}</th>
                                                <th className={styles.table_header_end_mat}>{i18n.t('Edit')}</th>
                                                <th className={styles.table_header_icon}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {materialList.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.utroseno)).toFixed(2)} {item.jedinica_mere}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.cena_po_jm)).toFixed(2)} {this.state.currency.naziv}</td>
                                                    <td className={styles.table_row_text}>{(((Number(item.utroseno) * (Number(item.cena_po_jm))).toFixed(2)))} {this.state.currency.naziv}</td>
                                                    <td className={styles.table_row_end_mat}>
                                                        <img src="./edit.svg" height="24px" className={styles.delete_button_table} onClick={() => {
                                                            this.setState({ editMaterial: item.matId }, () => {
                                                                this.handleMaterials();
                                                            })
                                                        }} />
                                                    </td>
                                                    <td className={styles.table_row_icon}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteMaterials(item.matId)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                    {totalMaterials > 0 && <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
                                        {i18n.t('Total spent')}: {totalMaterials.toFixed(2)} {this.state.currency.naziv}
                                    </div>}
                                </div>
                            }
                        </div>

                        {this.state.hasYieldIndicator && <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./plant.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Products')}</div>
                                    <div className={styles.add_button} onClick={() => this.handleProducts()}>
                                        <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleProducts()} />
                                    </div>
                                </div>
                                {/* <img src="./edit.svg" height='26px' className={styles.edit_icon} /> */}
                            </div>
                            {productList.length === 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no added products')}</div>
                                </div>
                            }
                            {productList.length > 0 && Object.keys(this.state.currency).length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Yield')}</th>
                                                <th className={styles.table_header}>{i18n.t('Amount')}</th>
                                                <th className={styles.table_header}>{i18n.t('Price per unit')}</th>
                                                <th className={styles.table_header}>{i18n.t('Total')}</th>
                                                <th className={styles.table_header_end_mat}>{i18n.t('Edit')}</th>
                                                <th className={styles.table_header_icon}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {productList.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.prinos)).toFixed(2)} {item.jedinica_mere}</td>
                                                    <td className={styles.table_row_text}>{(Number(item.cena_po_jm)).toFixed(2)} {this.state.currency.naziv}</td>
                                                    <td className={styles.table_row_text}>{(((Number(item.prinos) * (Number(item.cena_po_jm))).toFixed(2)))} {this.state.currency.naziv}</td>
                                                    <td className={styles.table_row_end_mat}>
                                                        <img src="./edit.svg" height="24px" className={styles.delete_button_table} onClick={() => {
                                                            this.setState({ editMaterial: item.matId }, () => {
                                                                this.handleProducts();
                                                            })
                                                        }} />
                                                    </td>
                                                    <td className={styles.table_row_icon}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteProducts(item.matId)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                    {totalYield > 0 && <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
                                        {i18n.t('Total yield')}: {totalYield.toFixed(2)} {this.state.currency.naziv}
                                    </div>}
                                </div>
                            }
                        </div>}

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./tractor_2.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Machines')}</div>
                                    {(this.state.activityObj.pogonskeMasine === undefined || this.state.activityObj.pogonskeMasine.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleMachines()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleMachines()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.pogonskeMasine && this.state.activityObj.pogonskeMasine.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleMachines()} />}
                            </div>
                            {(this.state.activityObj.pogonskeMasine === undefined || this.state.activityObj.pogonskeMasine.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected machines')}</div>
                                </div>
                            }
                            {this.state.activityObj.pogonskeMasine && this.state.activityObj.pogonskeMasine.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Machines')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.pogonskeMasine.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv} ({item.pogonska_naziv} , {' ' + item.registarska_oznaka})</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteMachines(i)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./tractor_2.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Implements')}</div>
                                    {(this.state.activityObj.prikljucneMasine === undefined || this.state.activityObj.prikljucneMasine.length === 0) &&
                                        <div className={styles.add_button} onClick={() => this.handleImplements()}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleImplements()} />
                                        </div>
                                    }
                                </div>
                                {this.state.activityObj.prikljucneMasine && this.state.activityObj.prikljucneMasine.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleImplements()} />}
                            </div>
                            {(this.state.activityObj.prikljucneMasine === undefined || this.state.activityObj.prikljucneMasine.length === 0) &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no selected implements')}</div>
                                </div>
                            }
                            {this.state.activityObj.prikljucneMasine && this.state.activityObj.prikljucneMasine.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Implements')}</th>
                                                <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                            </tr>
                                            {this.state.activityObj.prikljucneMasine.map((item, i) => {
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{item.naziv}</td>
                                                    <td className={styles.table_row_end}>
                                                        <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteImplements(i)} />
                                                    </td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.section} style={{ marginBottom: '0px' }}>
                            <div className={styles.title_container}>
                                <div className={styles.title_left}>
                                    <img src="./edit_3.svg" height='26px' className={styles.title_icon} />
                                    <div className={styles.section_title}>{i18n.t('Description')}</div>
                                    {this.state.commentOpen &&
                                        <div className={styles.add_button} onClick={() => this.setState({ commentOpen: !this.state.commentOpen }, () => {
                                            if (this.state.comment !== '') this.handleComment(this.state.comment)
                                        })}>
                                            <div style={{ fontSize: 14, display: 'flex', flexDirection: 'row' }}>
                                                <img src="./save.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                                <span style={{ marginLeft: '8px' }}>{i18n.t('Save')}</span>
                                            </div>
                                        </div>
                                    }
                                    {this.state.commentOpen === false && this.state.comment === '' && this.state.activityObj.komentar === undefined &&
                                        <div className={styles.add_button} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })}>
                                            <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                        </div>
                                    }
                                    {/* {this.state.commentOpen === false && this.state.comment !== '' && this.state.activityObj.komentar !== undefined &&
                                        <div className={styles.add_button} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })}>
                                            <img src="./edit.svg" className={styles.add_icon} height='18px' onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />
                                        </div>
                                    } */}
                                </div>
                                {this.state.commentOpen === false && this.state.comment !== '' && this.state.activityObj.komentar !== undefined && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.setState({ commentOpen: !this.state.commentOpen })} />}

                            </div>
                            {this.state.activityObj.komentar !== undefined && this.state.comment !== '' && this.state.commentOpen === false &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.content_between}>
                                        <div className={styles.content_flex}>
                                            <div style={{ minWidth: 'fit-content' }}>
                                                <div className={styles.working_operation_color}>
                                                    {i18n.t('Description')}:
                                                </div>
                                                <div className={styles.working_operation}>
                                                    {this.state.comment}
                                                </div>
                                            </div>
                                        </div>
                                        <img src="./delete.svg" height="32px" className={styles.delete_button} style={{ marginTop: '22px' }} onClick={() => this.setState({ comment: '' }, () => {
                                            this.deleteComment();
                                        })} />
                                    </div>
                                </div>
                            }
                            {this.state.activityObj.komentar === undefined &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_empty}>{i18n.t('You have no activity desrciption')}</div>
                                </div>
                            }
                        </div>

                        {this.state.commentOpen &&
                            <TextField
                                label={i18n.t('Activity description')}
                                value={this.state.comment}
                                style={{ width: '100%', marginTop: '10px', margin: '10px 8px 0px 8px' }}
                                multiline
                                rows={4}
                                variant='outlined'
                                onChange={(e) => { this.setState({ comment: e.target.value }); }} >
                            </TextField>
                        }

                        <div style={{ position: 'relative', height: '200px' }}>
                            <nav className={status["menu"]}>
                                <input type="checkbox" href="#" className={status["menu-open"]} name="menu-open" id="menu-open" checked={this.state.statusOpen} />
                                <label className={status["menu-open-button"]} style={{ background: statusColor }} onClick={() => this.handleStatusOpen()}>
                                    {this.state.activityObj.id_status === 0 && this.state.statusOpen === false && <div>
                                        {/* <span className={`${status.lines} ${status["line-1"]}`}></span>
                                        <span className={`${status.lines} ${status["line-2"]}`}></span>
                                        <span className={`${status.lines} ${status["line-3"]}`}></span> */}
                                        <img src="./add.svg" className={styles.add_icon_menu} height='28px' />
                                    </div>}
                                    {this.state.activityObj.id_status !== 0 && this.state.statusOpen === false &&
                                        <img src={statusIcon} height="32px" style={this.state.activityObj.id_status === 3 ? { padding: '14px 0px 0px 5px' } : { padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                    }
                                    {this.state.statusOpen && <img src="./status/close.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />}
                                </label>                               
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(2) }} className={`${status["menu-item"]} ${status["progress"]}`} onMouseOver={() => this.setState({ tooltipProgress: !this.state.tooltipProgress })} onMouseOut={() => this.setState({ tooltipProgress: !this.state.tooltipProgress })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipProgress && this.state.statusOpen) ? status.tooltip_progress_hover : status.tooltip_progress}>{i18n.t('In progress')}</div>
                                    <img src="./status/utoku.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} /> </a>
                                <a onClick={() => { if (this.state.statusOpen) this.handleStatus(1) }} className={`${status["menu-item"]} ${status["finished"]}`} onMouseOver={() => this.setState({ tooltipFinished: !this.state.tooltipFinished })} onMouseOut={() => this.setState({ tooltipFinished: !this.state.tooltipFinished })} style={{ cursor: this.state.statusOpen ? 'pointer' : 'auto' }}>
                                    <div className={(this.state.tooltipFinished && this.state.statusOpen) ? status.tooltip_finished_hover : status.tooltip_finished}>{i18n.t('Finished')}</div>
                                    <img src="./status/zavrsen.svg" height="32px" style={{ padding: '14px 0px 0px 0px' }} className={status.icon_filter} />
                                </a>
                                <a className={`${status["menu-item"]} ${status["purple"]}`} style={{ display: 'none' }}>
                                    <i style={{ display: 'none' }}></i>
                                </a>                             
                            </nav>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '-50px', fontSize: '22px', opacity: this.state.statusOpen ? '0' : '1', transition: 'opacity 0.5', fontWeight: '500', visibility: this.state.statusOpen ? 'hidden' : 'initial' }}>{statusText}</div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <div className={styles.save_button} onClick={() => this.goTo('/')}>
                                {i18n.t('Cancel')}
                            </div>
                            <div className={saveDisabled ? styles.save_button_disabled : styles.save_button} onClick={() => {
                                if (this.state.activityObj.id_radna_operacija &&
                                    this.state.activityObj.datum_pocetka &&
                                    this.state.activityObj.table &&
                                    this.state.activityObj.table.length > 0 &&
                                    this.state.activityObj.id_status !== 0
                                ) {
                                    if (this.state.activityObj.redovi !== undefined && this.state.activityObj.redovi.length > 0 
                                        && !this.state.activityObj.redovi.some(red => red.red_odabran)){
                                            wentWrong(i18n.t('You must pick at least one row'))
                                        }
                                        else {
                                            this.handleAdd();
                                        }
                                }
                                else wentWrong(i18n.t('Enter required fields marked with *'))
                            }}>
                                {editText ? i18n.t('Edit') : (this.state.copy ? i18n.t('Copy') : i18n.t('Add'))}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>{i18n.t('Enter required fields marked with *')}</div>
                    </div>
                </div>
                {this.state.workingOperationOpen && <WorkingOperation open={this.state.workingOperationOpen} handleClose={this.handleWorkingOperation} handleWorkingOperationObj={this.handleWorkingOperationObj} workingOperation={{ label: this.state.workingOperationString, value: this.state.activityObj.id_radna_operacija }} workingOperationDescription={this.state.activityObj.opis_radna_operacija} />}
                {this.state.dateAndTimeOpen && <DateAndTime open={this.state.dateAndTimeOpen} handleClose={this.handleDateAndTime} handleDateAndTimeObj={this.handleDateAndTimeObj} activityObj={this.state.activityObj} />}
                {this.state.fieldsOpen && <Fields open={this.state.fieldsOpen} handleClose={this.handleFields} handleFieldsObj={this.handleFieldsObj} activityObj={this.state.activityObj} />}
                {this.state.materialsOpen && <Materials open={this.state.materialsOpen} handleClose={this.handleMaterialsClose} handleMaterialsObj={this.handleMaterialsObj} activityObj={this.state.activityObj} editMaterial={this.state.editMaterial} />}
                {this.state.productsOpen && <Products open={this.state.productsOpen} handleClose={this.handleProductsClose} handleProductsObj={this.handleProductsObj} activityObj={this.state.activityObj} editMaterial={this.state.editMaterial} />}
                {this.state.machinesOpen && <Machines open={this.state.machinesOpen} handleClose={this.handleMachines} handleMachinesObj={this.handleMachinesObj} activityObj={this.state.activityObj} />}
                {this.state.implementsOpen && <Implements open={this.state.implementsOpen} handleClose={this.handleImplements} handleImplementsObj={this.handleImplementsObj} activityObj={this.state.activityObj} />}
                {this.state.rowsOpen && <Rows isEdit={true} open={this.state.rowsOpen} handleClose={this.toggleRows} id_table={this.state.edit_tabla_id} rows={this.state.edit_table_redovi} handleRowsObj={this.handleRowsObj}/>}
            </div>
        );
    }
}